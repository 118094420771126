import React, { FC, FormEvent, Fragment, useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import {
  Button,
  Divider,
  FormField,
  Heading,
  Image,
  Input,
  InputValidationStatus,
  Link,
  Snackbar,
  Text,
} from '@legalshield/adonis-ux-framework';
import cn from 'classnames';

import { LoginService, UrlService } from '../../../services';

import './DetermineFlow.css';

// @ts-ignore
import accessOnlineLegalShield from './images/legalshield/access-online.svg';
// @ts-ignore
import enjoyToolsLegalShield from './images/legalshield/enjoy-tools.svg';
// @ts-ignore
import manageInfoLegalShield from './images/legalshield/manage-info.svg';
// @ts-ignore
import accessOnlineIDShield from './images/idshield/access-online.svg';
// @ts-ignore
import enjoyToolsIDShield from './images/idshield/enjoy-tools.svg';
// @ts-ignore
import manageInfoIDShield from './images/idshield/manage-info.svg';

export interface DetermineFlowProps {
  onContinue({ username }: { username: string }): void;
  onBack(): void;
  username: string;
  showUserNotFoundAlert: boolean;
  continueToCreateAccount?(e: any): any;
}

interface FormValues {
  username: string;
}

export const DetermineFlow: FC<DetermineFlowProps> = ({
  onContinue,
  onBack,
  username,
  showUserNotFoundAlert,
  continueToCreateAccount,
}) => {
  const [usernameInputStatus, setUsernameInputStatus] = useState<InputValidationStatus>('');
  const [searchParams, setSearchParams] = useSearchParams();
  const forceLogout = searchParams.get('forceLogout') === 'true';

  useEffect(() => {
    if (forceLogout) {
      const logoutUser = async () => {
        // Logout user
        await LoginService.logoutUser();

        // Remove forceLogout query param from URL
        searchParams.delete('forceLogout');
        setSearchParams(searchParams, { replace: true });
      };

      logoutUser();
    }
  }, [forceLogout]);

  useEffect(() => {
    if (showUserNotFoundAlert) {
      setUsernameInputStatus('invalid');
    }
  }, [showUserNotFoundAlert]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      username,
    },
  });

  // Check if the user has access
  const showFeaturesCard = UrlService.isAccess() || UrlService.isActivate();

  // Get the biometric type from the URL query parameter
  const [biometric, setBiometric] = useState(new URLSearchParams(window.location.search).get('biometric'));

  // Get if the password reset is expired from the URL query parameter
  const [isPasswordResetExpired, setIsPasswordResetExpired] = useState(
    new URLSearchParams(window.location.search).get('expired') == 'true'
  );

  // Check if the user is using IDShield
  const isIDShield = UrlService.isIDShield();

  const determineFlow: SubmitHandler<FormValues> = (data) => {
    onContinue({ username: data.username });
  };

  /**
   * Handle biometric authentication and send a message to the React Native WebView.
   * @param e - The form event
   */
  const handleBiometrics = (e: FormEvent) => {
    e.preventDefault();
    // @ts-ignore
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        type: 'biometric_tapped',
      })
    );
  };

  const getBiometricIcon = () => {
    if (biometric === 'FaceID') {
      return 'marks_apple_face_id';
    } else if (biometric === 'TouchID') {
      return 'marks_apple_touch_id';
    } else {
      return 'action_touch_id';
    }
  };

  const forgotEmailUsername = () => {
    window.location.href = '/new-forgot-password';
  };

  const classContainer = 'lsux-form-field-container';
  const cnValidation = {
    [`${classContainer}__hint_text`]: status == 'valid' || status == 'warning',
    [`${classContainer}__hint_text--invalid`]: status == 'invalid',
    ['pl-2']: true,
  };

  return (
    <>
      <div className={'p-4'}>
        <div className={'mt-8'}>
          {isPasswordResetExpired && (
            <Snackbar
              classNames={['mb-3']}
              icon={'alert_error'}
              bold={true}
              severity={'error'}
              message={string_table.RESET_LINK_EXPIRED}
            />
          )}
          <Heading as="T28" text={string_table.DETERMINE_FLOW_TITLE} />
        </div>

        <div className={'mt-3'}>
          <Text text={string_table.DETERMINE_FLOW_SUB_TEXT} />
        </div>

        <div className={'mt-6'}>
          <form onSubmit={handleSubmit(determineFlow)} noValidate>
            <FormField label={string_table.DETERMINE_FLOW_EMAIL_ADDRESS} status={usernameInputStatus}>
              <Input
                type="email"
                placeholder={string_table.DETERMINE_FLOW_EMAIL_ADDRESS}
                autoComplete="username"
                {...register('username', {
                  required: string_table.DETERMINE_FLOW_EMAIL_ADDRESS_REQUIRED,
                })}
              />
              {showUserNotFoundAlert ? (
                <div className={cn(`${classContainer}__hint`, `pt-2`)}>
                  <span className="ml-3">
                    <Text textSize="medium" classNames={'username-hint-error'}>
                      <p>
                        {string_table.DETERMINE_FLOW_NOT_FOUND_ERROR_MESSAGE}{' '}
                        <RouterLink onClick={continueToCreateAccount} to="#">
                          {string_table.DETERMINE_FLOW_NOT_FOUND_ERROR_CREATE_ACCOUNT}
                        </RouterLink>
                        .
                      </p>
                    </Text>
                  </span>
                </div>
              ) : (
                <Fragment />
              )}
            </FormField>

            {errors.username && <Text text={errors.username.message || ''} classNames={['text-error']} />}

            <Button type="submit" label={string_table.DETERMINE_FLOW_BUTTON} stretch={true} classNames={['mt-6']} />
          </form>
        </div>

        {!!biometric && (
          <>
            <Divider classNames={['mt-5']} label={string_table.HOME_OR} />
            <div className={'biometric-container'}>
              <Link
                text={string_table.DETERMINE_FLOW_BIOMETRIC.replace('%biometric%', biometric)}
                icon={getBiometricIcon()}
                iconPosition={'left'}
                classNames={['mt-5']}
                onClick={handleBiometrics}
                textSize={'large'}
              />
            </div>
          </>
        )}
      </div>

      {showFeaturesCard && (
        <div className={'p-4'}>
          <div className={'mt-6'}>
            <Heading as="T20" text={string_table.DETERMINE_FLOW_ACCESS_TITLE} />
          </div>

          <div className={'access-card mt-4'}>
            {/* Access card 1 */}
            <div className={'access-card-row'}>
              <div>
                <Image
                  classNames={'access-card-row-image'}
                  src={isIDShield ? accessOnlineIDShield : accessOnlineLegalShield}
                />
              </div>
              <div>
                <Heading as="T14" text={string_table.DETERMINE_FLOW_ACCESS_SUB_TITLE_1} />

                <div className={'mt-2'}>
                  <Text text={string_table.DETERMINE_FLOW_ACCESS_SUB_TEXT_1} />
                </div>
              </div>
            </div>

            {/* Access card 2 */}
            <div className={'access-card-row'}>
              <div>
                <Image
                  classNames={'access-card-row-image'}
                  src={isIDShield ? manageInfoIDShield : manageInfoLegalShield}
                />
              </div>
              <div>
                <Heading as="T14" text={string_table.DETERMINE_FLOW_ACCESS_SUB_TITLE_2} />

                <div className={'mt-2'}>
                  <Text text={string_table.DETERMINE_FLOW_ACCESS_SUB_TEXT_2} />
                </div>
              </div>
            </div>

            {/* Access card 3 */}
            <div className={'access-card-row'}>
              <div>
                <Image
                  classNames={'access-card-row-image'}
                  src={isIDShield ? enjoyToolsIDShield : enjoyToolsLegalShield}
                />
              </div>
              <div>
                <Heading as="T14" text={string_table.DETERMINE_FLOW_ACCESS_SUB_TITLE_3} />

                <div className={'mt-2'}>
                  <Text text={string_table.DETERMINE_FLOW_ACCESS_SUB_TEXT_3} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className={'mt-4'}>
        <Button
          label={string_table.DETERMINE_FLOW_FORGOT_EMAIL_USERNAME}
          stretch={true}
          variant={'tertiary'}
          onMouseDown={forgotEmailUsername}
          onClick={forgotEmailUsername}
        />
      </div>
    </>
  );
};
