import { useEffect } from 'react';
import { UrlService } from '../services';
import { useThemisAnalytics } from '../hooks/useThemisAnalytics';
import { CHECKOUT_APP_ALIASES } from '../config/constants';

export function useCheckoutStep(checkoutStep: string) {
  const app = UrlService.getApp();

  useEffect(() => {
    if (CHECKOUT_APP_ALIASES.includes(app ?? '')) {
      if (window.dataLayer) {
        window.dataLayer.push({ ecommerce: null });
      }

      const event = {
        ecommerce: {
          checkout: {
            actionField: {
              step: 1,
              option: checkoutStep,
            },
          },
        },
        eventAction: 'Step 1',
        eventLabel: `${checkoutStep} Step`,
      };
      useThemisAnalytics().triggerCustomEvent('checkout', event);
    }
  }, [app]);
}
