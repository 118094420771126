// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.link--config {
  display: block;
  margin: 8px 0 0;
  text-align: end;
}

.resignin {
    color: var(--brand-color-prim200);
    font: var(--t16);
}
`, "",{"version":3,"sources":["webpack://./src/components/v1/login/Login.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,eAAe;EACf,eAAe;AACjB;;AAEA;IACI,iCAAiC;IACjC,gBAAgB;AACpB","sourcesContent":[".link--config {\n  display: block;\n  margin: 8px 0 0;\n  text-align: end;\n}\n\n.resignin {\n    color: var(--brand-color-prim200);\n    font: var(--t16);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
