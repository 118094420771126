/**
 * @description Old ForgotEmailUsername
 * - routes /forgot-email-username
 * - overall functionality: directs user to retrieve their username
 * - list of pages, flows that lead here: Login, LoginFlow, DetermineFlow
 * - page specific logic: display the forgot email/username page
 * - differences between old and new: This page contains contact info. new page contains text for contact info and filed to retieve password.
 */

import React from 'react';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import { GridCol, GridRow, Heading, Text } from '@legalshield/adonis-ux-framework';
import { useDocTitle } from '../../../hooks';

declare const pplsi: any;

export const ForgotEmailUsername = () => {
  // Set the document title using the useDocTitle hook
  useDocTitle(string_table.FORGOT_USERNAME_TITLE);

  return (
    <GridRow variant="pillar">
      <GridCol>
        {/* Display the heading */}
        <Heading as="T28" text={string_table.FORGOT_USERNAME_TITLE} />

        <div className="mt-5">
          {/* Display the forgot username text */}
          <Text text={string_table.FORGOT_USERNAME_TEXT1} />
        </div>

        <div className="mt-5">
          {/* Display the support phone number */}
          {pplsi.phoneNumber && (
            <div>{parse(string_table.FORGOT_USERNAME_SUPPORT.replace(/\%phone\%/g, pplsi.phoneNumber))}</div>
          )}

          {/* Display the associate phone number */}
          {pplsi.phoneNumberAssociate && (
            <div>{parse(string_table.FORGOT_USERNAME_ASSOC.replace(/\%phone\%/g, pplsi.phoneNumberAssociate))}</div>
          )}

          {/* Display the email */}
          {pplsi.email && <div>{parse(string_table.FORGOT_USERNAME_EMAIL.replace(/\%email\%/g, pplsi.email))}</div>}
        </div>

        <div className="mt-5">
          {/* Display the link to go back to the login page */}
          <Link to={'/login' + location.search} className="link">
            {string_table.FORGOT_USERNAME_BACK}
          </Link>
        </div>
      </GridCol>
    </GridRow>
  );
};
