/**
 * @description: Forgot Password Prompt component
 * - routes: none
 * - overall functionality: prompt user to reset password used in PasswordFactor, LoginFlow
 * - list of pages, flows that lead here: LoginFlow
 * - page specific logic: prompt user to reset password
 */

import React, { FC } from 'react';
import { Button } from '@legalshield/adonis-ux-framework';

export interface ForgotPasswordPromptProps {
  username: string;
}

export const ForgotPasswordPrompt: FC<ForgotPasswordPromptProps> = ({ username }) => {
  // Function to handle the click event of the forgot password link
  const forgotPasswordLinkClicked = (e: React.FormEvent) => {
    e.preventDefault();

    let searchUrl = '';

    // Construct the search URL based on the current location and the username
    if (location.search === '' && username) {
      searchUrl = `/new-forgot-password?email=${username}`;
    } else if (username) {
      searchUrl = '/new-forgot-password' + location.search + `&email=${username}`;
    } else {
      searchUrl = '/new-forgot-password' + location.search;
    }

    // Navigate to the constructed search URL
    window.location.href = searchUrl;
  };

  return (
    <div className={'mt-4'}>
      {/* Button component to trigger the forgot password link click */}
      <Button
        label={string_table.LOGIN_FLOW_FORGOT_EMAIL_PASSWORD}
        stretch={true}
        variant={'tertiary'}
        onClick={forgotPasswordLinkClicked}
      />
    </div>
  );
};
