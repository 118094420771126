/**
 * @description SupportCard component
 * - routes: none
 * - overall functionality: user can call support
 *  - page specific functionality: component that displays a card with support information - used on various login pages
 */

import { Card, Button, Container, Heading, Text } from '@legalshield/adonis-ux-framework';
import React, { FC } from 'react';

declare const pplsi: any;

const SupportCard: FC = () => {
  // Function to handle the button click event
  const handleClick = () => {
    // Redirect the user to the phone number using the tel protocol
    window.location.href = `tel:${pplsi.phoneNumber}`;
  };

  return (
    <Card classNames={['mt-5']}>
      <Card.Content>
        <Container flexbox flexDirection="column" alignItems="center" justifyContent="center">
          <Heading as="T20" text={string_table.SUPPORT_CARD_TITLE} classNames={['pb-2']} />
          <Text as="p" text={string_table.SUPPORT_CARD_BODY} classNames={['pb-2']} />
          <Button
            onClick={handleClick}
            label={`${string_table.SUPPORT_CARD_BUTTON} ${pplsi.phoneNumber}`}
            variant="tertiary"
            iconLeft="comm_call_phone"
          />
        </Container>
      </Card.Content>
    </Card>
  );
};

export default SupportCard;
