import { useThemisAnalytics } from '../hooks/useThemisAnalytics';

const recordLoginGtmEvent = () => {
  // trigger GA4 recommended event for login
  useThemisAnalytics().triggerLoginEvent({
    method: "email"
  });
};

const recordSignUpGtmEvent = () => {
  // trigger GA4 recommended SignUp event
  useThemisAnalytics().triggerSignUpEvent({
    method: "email"
  });
};

export default {
  recordLoginGtmEvent,
  recordSignUpGtmEvent
};
