/**
 * @description Old SignUp component
 * - routes /sign-up
 * - overall functionality: user can sign up for an account
 * - page specific functionality: component that begins the sign up process
 * - components: LoginService, UrlService, CustomTitleService, GtmService, PasswordForm, useLoader, useDocTitle, useCheckoutStep
 * - list of pages, flows that lead here: Home
 */

import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Alert,
  Button,
  Checkbox,
  Form,
  GridCol,
  GridRow,
  Heading,
  Text,
  useForm,
} from '@legalshield/adonis-ux-framework';
import { RegEx } from '@legalshield/frontend-commons';

import SupportCard from '../supportCard/SupportCard';
import { GTM_SIGN_UP } from '../../../config/constants';
import { Loader } from '../../../hooks/Loader';
import { LoginService } from '../../../services';
import { PasswordForm } from '../../passwordForm/PasswordForm';
import { UrlService, CustomTitleService } from '../../../services';
import { useDocTitle, useCheckoutStep } from '../../../hooks';
import { useLoader } from '../../../hooks/useLoader';

export const SignUp: FC = () => {
  // Set document title
  useDocTitle(string_table.SIGNUP_DOC_TITLE);

  // Track checkout step for GTM
  useCheckoutStep(GTM_SIGN_UP);

  // Initialize loader state
  const loader = useLoader();

  // Get the current app
  const app = UrlService.getApp();

  // Check if the app is checkoutv3
  const isCheckout = app === 'checkoutv3';

  // State for "Stay Signed In" checkbox
  const [staySignedIn, setStaySignedIn] = useState<boolean | null>(false);

  // Form values and form object for email and password fields
  const [formValues, formObject] = useForm(
    {
      email: {
        autoComplete: 'email',
        errorMessage: string_table.ERROR_EMAIL_PATTERN,
        label: string_table.SIGNUP_EMAIL,
        regEx: RegEx.emailRegex,
        required: true,
        type: 'email',
        value: '',
      },
      ...PasswordForm.passwordFields,
    },
    null
  );

  // Destructure email and password from form values
  const { email, password } = formValues;

  // Handle sign up form submission
  const handleSignUp = async (e: React.FormEvent) => {
    e.preventDefault();

    // Show loading state
    loader.Loading();

    try {
      // Call the sign up API
      const results = await LoginService.signUpUser({
        email: email.value,
        password: password.value,
        persist: !!staySignedIn,
        realm: 'User',
      });

      // Handle different API response statuses
      if (results.status >= 200 && results.status < 300) {
        // Sign up success
        isSuccess();
        return;
      } else if (results.status === 422) {
        // Unprocessable entity
        isUnprocessableEntity();
        return;
      } else if (results.status === 418) {
        // User already found
        isUserFound();
        return;
      }
    } catch {
      // Handle sign up error
      loader.Error(string_table.SIGNUP_GENERIC_ERROR);
    }
  };

  // Get the title based on the current app
  const title = CustomTitleService.getTitleByApp('SIGNUP');

  // Show error message for user already found
  const isUserFound = () => {
    const { search } = window.location;

    let routerLink = (
      <span>
        {string_table.SIGNUP_EMAIL_HASPASSWORD_PART1}
        <Link to={`/login${search}`}>{string_table.SIGNUP_EMAIL_HASPASSWORD_SIGNIN}</Link>
        {string_table.SIGNUP_EMAIL_HASPASSWORD_PART2}
      </span>
    );

    let message = routerLink as unknown as string;
    loader.Error(message, { alertBold: false });
  };

  // Show error message for unprocessable entity
  const isUnprocessableEntity = () => {
    const { search } = window.location;
    let routerLink = (
      <span>
        {string_table.SIGNUP_EMAIL_TAKEN_PART1}
        <Link to="/verify-identity">{string_table.SIGNUP_LINK}</Link>
        {string_table.SIGNUP_EMAIL_TAKEN_PART2}
      </span>
    );

    if (search.includes('checkoutv3')) {
      routerLink = routerLink = <span>{string_table.SIGNUP_EMAIL_TAKEN}</span>;
    }

    let message = routerLink as unknown as string;
    loader.Error(message, { alertBold: false });
  };

  // Handle sign up success
  const isSuccess = () => {
    window.location.href = UrlService.getLoggedInRedirect({});
    return;
  };

  return (
    <>
      <GridRow variant="pillar">
        <GridCol>
          {/* Display loader based on loader state */}
          <Loader loaderState={loader.loaderState} />
          {/* Display heading based on the current app */}
          <Heading as="T28" classNames={['mt-4']} text={title} />
          <div style={{ display: 'flex' }} className="mt-3">
            <Text text={string_table.SIGNUP_ALREADY_HAVE_ACCOUNT} textSize="large" classNames={['mr-3']} />
            <Link to={'/login' + location.search} className="link">
              {string_table.HOME_SIGN_IN}
            </Link>
          </div>
          {isCheckout ? (
            <div className={'mt-4'}>
              {/* Display Primerica tip for checkout*/}
              <Alert
                icon={'alert_information'}
                bold={false}
                content={string_table.SIGNUP_PRIMERICA_TIP}
                severity={'default'}
                type="default"
              />
            </div>
          ) : (
            <></>
          )}
          <Form onSubmit={handleSignUp} noValidate>
            {/* Form component for sign up */}
            {formObject.renderForm()}
            <Checkbox
              id="persist"
              name="persist"
              rightLabel={string_table.SIGNUP_KEEP_SIGNED_IN}
              checked={!!staySignedIn}
              onCheckedChange={setStaySignedIn}
              classNames={['mt-5']}
            />
            <div className="lsux-form-field-container">
              <Button
                label={string_table.SIGNUP_SIGN_UP} // Display sign up button
                disabled={!formObject.isValid}
                variant="primary"
                type="submit"
                stretch={true}
              />
            </div>
            {/* Display support card component */}
            <SupportCard />
            <div className="lsux-form-field-container">
              <Text
                text={string_table.SIGNUP_POLICY_DISCLAIMER} // Display policy disclaimer
                classNames={['disclaimer-text']}
                as="p"
                textSize="small"
              />
            </div>
          </Form>
        </GridCol>
      </GridRow>
    </>
  );
};
