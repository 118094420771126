/**
 * @description PossessionFactor component
 * - routes: none
 * - components: LoginService
 * - overall functionality: verify user with possession factor
 * - list of pages: none
 * - page specific logic: verify user with possession factor using verification for google or sms
 */

import React, { useState, useEffect } from 'react';
import {
  Accordion,
  Button,
  Container,
  Form,
  FormField,
  Heading,
  Input,
  InputValidationStatus,
  Select,
  Text,
} from '@legalshield/adonis-ux-framework';
import { Loader } from '../../../../hooks/Loader';
import { useLoader } from '../../../../hooks/useLoader';
import { LoginService } from '../../../../services';

import '../Login.css';

interface OptionItem {
  label: string;
  value: string;
}
interface FactorObject {
  type: string;
  verified: boolean;
  name: string;
  id: string;
  primary: boolean;
}

export interface PossessionProps {
  factors: Array<FactorObject>;
  token: string;
  onVerify?(arg?: unknown, arg2?: unknown): unknown;
  onCancel(): unknown;
}

export const PossessionFactor: React.FC<PossessionProps> = ({
  factors,
  token,
  onVerify,
  onCancel,
}: PossessionProps) => {
  // State variables
  const [currentFactor, setCurrentFactor] = useState({} as FactorObject);
  const [verifiedCount, setVerifiedCount] = useState(0);
  const [options, setOptions] = useState([{} as OptionItem]);
  const [codeState, setCodeState] = useState({
    value: '',
    isFocus: false,
    isVisited: false,
    isValid: false,
  });
  const loader = useLoader();

  // Initialize the component
  useEffect(() => {
    let initialFactorIndex = -1;
    let options = [];
    let vCount = 0;

    // Iterate through the factors to populate the options and count the verified factors
    for (var i = 0; i < factors.length; i++) {
      if (factors[i].verified) {
        vCount++;
        var name = '';
        if (factors[i].type === 'SMS') name = string_table.HOME_2FA_SMS + ': ';
        else if (factors[i].type === 'GOOGLE') name = string_table.HOME_2FA_AUTHENTICATOR + ': ';
        name = name + factors[i].name;
        options.push({ label: name, value: factors[i].id });
        if (factors[i].primary || initialFactorIndex == -1) {
          initialFactorIndex = i;
        }
      }
    }

    // Set the verified count and options
    setVerifiedCount(vCount);
    setOptions(options);

    // Set the current factor if there is an initial factor
    if (initialFactorIndex != -1) {
      setCurrentFactor(factors[initialFactorIndex]);
    }
  }, []);

  // Handle input change event
  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCodeState({ ...codeState, value: e.target.value, isValid: e.target.value.length === 6 });
  };

  // Handle verify button click event
  const onVerifyClicked = (e: React.FormEvent) => {
    e.preventDefault();
    if (onVerify) {
      onVerify(currentFactor.id, codeState.value);
    }
  };

  // Send verification code
  const sendCode = async (factorId: string, repeat: boolean) => {
    loader.Loading();
    try {
      const results = await LoginService.sendCode({ accessToken: token, factorId: factorId });
      if (results.status >= 200 && results.status < 300) {
        if (repeat) {
          loader.Success(string_table.HOME_2FA_RESENT);
        } else {
          loader.Blank();
        }
        return;
      }
    } catch {}

    loader.Error(string_table.HOME_2FA_RESENT_ERROR);
  };

  // Handle send code button click event
  const onSendCodeClicked = () => {
    sendCode(currentFactor.id, true);
  };

  // Handle select change event
  const onSelectChange = (value: string) => {
    debugger;
    for (var i = 0; i < factors.length; i++) {
      if (value === factors[i].id) {
        setCurrentFactor(factors[i]);
        if (factors[i].type === 'SMS') {
          sendCode(factors[i].id, false);
        }
        return;
      }
    }
  };

  // Handle sign out button click event
  const signOut = (e: React.FormEvent) => {
    e.preventDefault();
    onCancel();
  };

  return (
    <>
      {/* Render the heading if there is a current factor */}
      {currentFactor && <Heading classNames={['pt-3']} as="T14" text={string_table.HOME_2FA_TITLE} />}

      {/* Render the form if there is a current factor */}
      {currentFactor && (
        <>
          <Form onSubmit={onVerifyClicked}>
            {/* Render the form field for the verification code */}
            <FormField
              id="code"
              label={string_table.HOME_2FA_CODE}
              status={!codeState.isFocus && !codeState.isValid && codeState.isVisited ? 'invalid' : 'valid'}
              validationHint={!codeState.isFocus && !codeState.isValid && codeState.isVisited ? 'Code is required' : ''}
              description={
                currentFactor.type === 'GOOGLE'
                  ? string_table.HOME_2FA_AUTHENTICATORINSTRUCTIONS.replace('%name%', currentFactor.name).replace(
                      '%domain%',
                      pplsi.realmDomain
                    )
                  : string_table.HOME_2FA_SMSINSTRUCTIONS.replace('%name%', currentFactor.name)
              }
            >
              {/* Render the input field for the verification code */}
              <Input
                name="code"
                autoComplete="one-time-code"
                inputMode="numeric"
                placeholder={string_table.HOME_2FA_CODE}
                value={codeState.value}
                status={
                  (!codeState.isVisited
                    ? ''
                    : !codeState.isValid && !codeState.isFocus
                      ? 'invalid'
                      : 'valid') as InputValidationStatus
                }
                onChange={onInputChange as React.FormEventHandler}
                onFocus={() => {
                  setCodeState({ ...codeState, isVisited: true, isFocus: true });
                }}
                onBlur={() => {
                  setCodeState({ ...codeState, isFocus: false });
                }}
              />
            </FormField>
            <>
              {/* Render additional elements for SMS factor */}
              {currentFactor.type === 'SMS' && (
                <>
                  <Container classNames={['mt-5']} flexbox={true} justifyContent="space-between" alignItems="baseline">
                    <label className="lsux-form-field-container__label">{string_table.HOME_2FA_NEWSMSCODE}</label>
                    <Button
                      classNames={['ml-4']}
                      label={string_table.HOME_2FA_RESEND}
                      onClick={onSendCodeClicked}
                      variant="tertiary"
                    />
                  </Container>
                  <Loader classNames={['mt-5']} loaderState={loader.loaderState} />
                </>
              )}
            </>
            {/* Render the verify button */}
            <Button
              label={string_table.HOME_2FA_VERIFY}
              type="submit"
              disabled={!codeState.isValid}
              stretch={true}
              classNames={['mt-5']}
            />
          </Form>
          {/* Render the accordion for additional options */}
          <Accordion classNames={['mt-3']} title={string_table.HOME_2FA_HAVINGTROUBLE}>
            {/* Render the alternate factor select if there are multiple factors */}
            {factors.length > 1 && verifiedCount > 1 && (
              <div className="mb-5">
                <label className="lsux-form-field-container__label">{string_table.HOME_2FA_ALTERNATE}</label>
                <Select options={options} value={currentFactor.id} onValueChange={onSelectChange} />
              </div>
            )}

            {/* Render the phone number for support */}
            <div className="mb-5">
              <label className="lsux-form-field-container__label">{string_table.HOME_2FA_STILLHAVINGTROUBLE}</label>
              <Text textSize="small">{string_table.HOME_2FA_PHONE.replace('%phone%', pplsi.phoneNumber)}</Text>
            </div>
          </Accordion>
          {/* Render the sign out link */}
          <a className="link--config mt-3" href="#" onClick={signOut}>
            {string_table.HOME_2FA_STARTOVER}
          </a>
        </>
      )}
    </>
  );
};
