import { SDKDirect } from '@legalshield/frontend-commons';
import Cookies from 'js-cookie';
import { UrlService } from '.';

// Sometimes Users might be in the wrong "realm" domain. We help them by substituting it for them.
// This is specially useful for Associates going to shieldatwork.com
const substituteRealm = async (usernameOrEmail: string, intendedRealm: string): Promise<string> => {
  const encodedUsernameOrEmail = encodeURIComponent(usernameOrEmail).replace(/%20/g, '+');
  const response = await SDKDirect.getJson(`/v1/identities/realms?emailOrUsername=${encodedUsernameOrEmail}`);
  const realmMatches = response?.data.includes(intendedRealm);
  if (!realmMatches) {
    if (response?.data.length === 1) {
      intendedRealm = response.data[0];
    }
  }
  return intendedRealm;
};

const sendCode = async ({ accessToken, factorId }: { accessToken: string | undefined; factorId: string }) => {
  const results = await SDKDirect.postJson(
    'v1/tokens/sendCode',
    {
      accessToken: accessToken,
      factorId: factorId,
    },
    'POST',
    {}
  );
  return results;
};

const factorUser = async ({
  accessToken,
  factorId,
  code,
  persist,
}: {
  accessToken: string | undefined;
  factorId: string;
  code: string;
  persist: boolean;
}) => {
  const results = await SDKDirect.postJson(
    'v1/tokens/code',
    {
      accessToken: accessToken,
      factorId: factorId,
      code: code,
      persist: !!persist,
    },
    'POST',
    {}
  );
  return results;
};

const loginUser = async ({
  username,
  password,
  persist,
  realm,
}: {
  username: string;
  password: string;
  realm: string;
  persist: boolean;
}) => {
  realm = await substituteRealm(username, realm);
  const results = await SDKDirect.postJson(
    'v1/tokens/password',
    {
      username: username,
      password: password,
      realm: realm,
      persist: !!persist,
      mobile: false,
    },
    'POST',
    {}
  );
  return results;
};

const logoutUser = async () => {
  await SDKDirect.getJson('v1/logout');
};

const signUpUser = async ({
  email,
  password,
  persist,
  realm,
}: {
  email: string;
  password: string;
  realm: string;
  persist: boolean;
}) => {
  realm = await substituteRealm(email, realm);
  const results = await SDKDirect.postJson(
    'v1/users/create',
    {
      emails: [email],
      password: password,
      realm: realm,
      persist: !!persist,
      mobile: false,
    },
    'POST',
    {}
  );
  return results;
};

const sendResetPasswordEmail = async ({ email, realm }: { email: string; realm: string }) => {
  realm = await substituteRealm(email, realm);
  const results = await SDKDirect.postJson(
    'v1/users/reset',
    {
      email: email,
      realm: realm,
      issuingUrl: window.location.origin,
    },
    'POST',
    {}
  );

  return results;
};

const sendVerifyIdentityEmail = async ({ email, realm }: { email: string; realm: string }) => {
  realm = await substituteRealm(email, realm);
  return await SDKDirect.postJson(
    'v1/users/verify',
    {
      email: email,
      realm: realm,
      issuingUrl: window.location.origin,
    },
    'POST',
    {}
  );
};

const sendPasswordReset = async ({ password, realm }: { password: string; realm: string }) => {
  const results = await SDKDirect.postJson(
    'v1/users/passwords',
    {
      password: password,
      realm: realm,
    },
    'POST',
    {}
  );
  return results;
};

const identitySearch = async (emailOrUsername: string, realm: string) => {
  realm = await substituteRealm(emailOrUsername, realm);
  return await SDKDirect.getJson(`/v1/identities?emailOrUsername=${emailOrUsername}&realm=${realm}`);
};

export default {
  sendCode,
  factorUser,
  loginUser,
  logoutUser,
  sendPasswordReset,
  sendResetPasswordEmail,
  signUpUser,
  sendVerifyIdentityEmail,
  identitySearch,
};
