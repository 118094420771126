const hostname = window.location.hostname;
const env = getCurrentEnv();

export function getDomainEnvironmentPrefix() {
  let envPrefix = '';
  if (hostname.includes('dev-') || hostname.includes('localhost')) {
    envPrefix = 'dev-';
  }
  if (hostname.includes('uat-')) {
    envPrefix = 'uat-';
  }
  return envPrefix;
}

function getCurrentEnv() {
  if (hostname.indexOf('uat-') > 0) {
    return 'uat';
  }
  if (hostname.indexOf('dev-') > 0) {
    return 'sandbox';
  }
  return 'production';
}

function envMap() {
  const envObj = {
    production: {
      gtm: {
        auth: '-g_gdq8mpXSg8PHfXMX3lQ',
        gtmId: 'GTM-NQ3J2B7',
        preview: 'env-1',
      },
    },
    sandbox: {
      gtm: {
        auth: '8ORNwoYVVPtSAsoW7UGgKw',
        gtmId: 'GTM-NQ3J2B7',
        preview: 'env-17',
      },
    },
    uat: {
      gtm: {
        auth: '5B6USoiYGGAjfirnUbMYCw',
        gtmId: 'GTM-NQ3J2B7',
        preview: 'env-21',
      },
    },
  };

  return envObj[env];
}
export const envMapped = envMap();
export const currentEnv = getCurrentEnv();
