/**
 * @description TermsAndPrivacy component
 * - routes: none
 * - overall functionality: user can see the terms and privacy policy
 *  - page specific logic: user is sent to the terms and privacy policy page
 */

import React, { FC } from 'react';
import { Link, Text } from '@legalshield/adonis-ux-framework';

import './terms-and-privacy.css';

export const TermsAndPrivacy: FC = () => (
  <div className={'mt-6 p-4 terms-acceptance'}>
    <Text text={string_table.LOGIN_FLOW_TERMS_BEGINNING} textSize={'small'} as={'span'} />
    <Link
      textSize={'small'}
      href={'https://www.pplsi.com/terms-service/'}
      text={string_table.LOGIN_FLOW_TERMS}
      textWeight={'semibold'}
      target={'_blank'}
    />
    <Text text={string_table.LOGIN_FLOW_TERMS_AND} textSize={'small'} as={'span'} />
    <Link
      textSize={'small'}
      href={'https://www.pplsi.com/privacy-policy/'}
      text={string_table.LOGIN_FLOW_PRIVACY}
      textWeight={'semibold'}
      target={'_blank'}
    />
  </div>
);
