/**
 * @description SetPasswordFlow component
 * - routes: none
 * - overall functionality: user can set a new password - attached to new-verify-identity route
 * - page specific functionality: component that begins process of resetting password
 * - components: UrlService
 */

import React, { useEffect } from 'react';

import { Button, Heading, Text, Image } from '@legalshield/adonis-ux-framework';
// @ts-ignore
import emailSentImageLegalShield from '../forgotPasswordFlow/images/legalshield/email.svg';
// @ts-ignore
import emailSentImageIDShield from '../forgotPasswordFlow/images/idshield/email.svg';
import './SetPasswordFlow.css';
import parse from 'html-react-parser';
import { UrlService } from '../../../services';

export interface SetPasswordFlowProps {
  onContinue({ email, useLoader }: { email: string; useLoader: boolean }): void;
  email: string | null;
}

export const SetPasswordFlow: React.FC<SetPasswordFlowProps> = ({ onContinue, email }: SetPasswordFlowProps) => {
  useEffect(() => {
    // Call the onContinue function when the component mounts
    onContinue({ email: email as string, useLoader: false });
  }, []);

  const isIDShield = UrlService.isIDShield();

  const setPasswordFlow = (e: React.FormEvent) => {
    e.preventDefault();

    // Call the onContinue function with useLoader set to true
    onContinue({ email: email as string, useLoader: true });
  };

  return (
    <>
      <div className={'p-4'}>
        <div className={'mt-6'}>
          {/* Render a heading component */}
          <Heading as="T28" text={string_table.SET_PASSWORD_FLOW_TITLE} />
        </div>

        <div className={'mt-5'}>
          <Text textSize={'large'}>
            <>
              {/* Render a text component with parsed HTML */}
              {parse(string_table.SET_PASSWORD_FLOW_SUB_TEXT.replace('%email%', email as string))}
            </>
          </Text>
        </div>

        <div className={'mt-12 email-sent-container'}>
          {/* Render an image component based on the value of isIDShield */}
          <Image src={isIDShield ? emailSentImageIDShield : emailSentImageLegalShield} />
        </div>

        <Button
          label={string_table.SET_PASSWORD_FLOW_BUTTON}
          stretch={true}
          variant={'secondary'}
          classNames={['mt-12']}
          onClick={setPasswordFlow}
        />
      </div>
    </>
  );
};
