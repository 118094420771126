import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { envMapped } from './config/envConfig';
import { AnalyticServiceVendor, ThemisAnalytics } from '@legalshield/frontend-analytics';

const root = createRoot(document.getElementById('root') as HTMLElement);

const base = document.getElementsByTagName('base')[0];
const baseUrl = base ? (base.getAttribute('href') ?? '') : '';

const analyticsConfig = {
  client: AnalyticServiceVendor.GoogleTagManager,
  config: {
    gtmService: envMapped.gtm,
  },
};

const themisAnalytics = new ThemisAnalytics(analyticsConfig);
export default themisAnalytics;

themisAnalytics.initialize();

Object.assign(string_table, string_table_market);

root.render(
  <React.StrictMode>
    <BrowserRouter basename={baseUrl}>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
