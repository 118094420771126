/**
 * @description Multi Factor Flow page
 * - routes: none
 * - components: LoginService
 * - overall functionality: user can enter a code to verify their identity
 * - list of pages, flows that lead here: LoginFlow
 * - page specific logic: user can enter a code to verify their identity
 */

import React, { FC, useEffect, useState } from 'react';

import {
  Accordion,
  Button,
  Form,
  Heading,
  Loader,
  Select,
  Text,
  useForm,
  useLoader,
} from '@legalshield/adonis-ux-framework';
import { LoginService } from '../../../services';

import './MultiFactorFlow.css';

interface OtherFactor {
  label: string;
  value: string;
}

interface Factor {
  type: string;
  verified: boolean;
  name: string;
  id: string;
  primary: boolean;
}

export interface MultiFactorFlow {
  onContinue({ code, factorId }: { code: string; factorId: string }): void; // Callback function triggered when the user clicks the continue button
  onBack(): void; // Callback function triggered when the user clicks the back button
  factors: Array<Factor> | null; // Array of factors used for multi-factor authentication
  accessToken: string; // Access token for authentication
}

export const MultiFactorFlow: FC<MultiFactorFlow> = ({ onContinue, onBack, factors, accessToken }) => {
  const [currentFactor, setCurrentFactor] = useState({} as Factor); // State variable to store the currently selected factor
  const [verifiedFactors, setVerifiedFactors] = useState([] as Factor[]); // State variable to store the verified factors
  const [otherFactors, setOtherFactors] = useState([] as OtherFactor[]); // State variable to store other factors
  const loader = useLoader(); // Custom hook for managing loading state

  const [formValues, formObject] = useForm(
    {
      code: {
        autoComplete: 'one-time-code',
        errorMessage: string_table.MFA_FLOW_CODE_REQUIRED,
        inputMode: 'numeric',
        label: string_table.MFA_FLOW_CODE,
        placeHolder: string_table.MFA_FLOW_CODE,
        required: true,
        type: 'text',
        value: '',
      },
    },
    null
  ); // Custom hook for managing form state

  useEffect(() => {
    // @ts-ignore
    document.querySelector('input[name="code"]')?.focus(); // Set focus on the code input field

    if (factors) {
      let otherFactors: OtherFactor[] = [];

      const verifiedFactors = factors.filter((f) => f.verified); // Filter out the verified factors

      setVerifiedFactors(verifiedFactors);

      verifiedFactors.forEach((v) => {
        let name = '';

        if (v.type === 'SMS') {
          name = string_table.HOME_2FA_SMS + ': ';
        } else if (v.type === 'GOOGLE') {
          name = string_table.HOME_2FA_AUTHENTICATOR + ': ';
        }

        name = name + v.name;

        otherFactors.push({ label: name, value: v.id });
      });

      setOtherFactors(otherFactors);

      // There is a chance that there is no primary factor in the verified factors.
      // So we need to check for that.
      let currentFactor = verifiedFactors.filter((v) => v.primary)[0];

      if (currentFactor) {
        setCurrentFactor(currentFactor);
      } else {
        setCurrentFactor(verifiedFactors[0]);
      }
    }
  }, []);

  const mfaFlow = (e: React.FormEvent) => {
    e.preventDefault(); // Prevent form submission

    onContinue({ code: formValues.code.value, factorId: currentFactor.id }); // Call the onContinue callback with the entered code and current factor ID
  };

  const backArrowClicked = () => {
    onBack(); // Call the onBack callback
  };

  const sendCodeClicked = (e: React.MouseEvent) => {
    e.preventDefault(); // Prevent default click behavior

    sendCode(currentFactor.id).then(); // Call the sendCode function with the current factor ID
  };

  const sendCode = async (factorId: string) => {
    loader.Loading(); // Show loading state

    const sendCodeResponse = await LoginService.sendCode({
      accessToken: accessToken,
      factorId: factorId,
    }); // Send code request to the server

    if (sendCodeResponse.status >= 200 && sendCodeResponse.status < 300) {
      loader.Success(string_table.HOME_2FA_RESENT); // Show success message
    } else {
      loader.Error(string_table.HOME_2FA_RESENT_ERROR); // Show error message
    }
  };

  const onSelectChange = (value: string) => {
    verifiedFactors.forEach((v) => {
      if (value === v.id) {
        setCurrentFactor(v); // Set the current factor to the selected factor

        if (v.type === 'SMS') {
          sendCode(v.id).then(); // If the selected factor is SMS, send a code
        }
      }
    });
  };

  return (
    <>
      <div className={'mt-4'}>
        <Button
          iconColor="N700"
          iconLeft="arrow_left_md"
          variant="tertiary"
          onClick={backArrowClicked}
          iconSize={'medium'}
        />
      </div>

      <div className={'p-4'}>
        <div className={'mt-4'}>
          <Heading as="T28" text={string_table.MFA_FLOW_TITLE} />
        </div>

        <div className={'mt-6'}>
          <Form onSubmit={mfaFlow}>
            {/* Display the form with the code input field */}
            {formObject.renderForm()}
            <div className={'mt-6 p-4 factor-description'}>
              <Text
                text={
                  currentFactor.type === 'GOOGLE'
                    ? string_table.MFA_2FA_AUTHENTICATOR_INSTRUCTIONS.replace('%name%', currentFactor.name).replace(
                        '%domain%',
                        pplsi.realmDomain
                      )
                    : string_table.MFA_FLOW_SMS_INSTRUCTIONS.replace("'%name%'", currentFactor.name)
                }
                textSize={'small'}
              />
            </div>
            <Button
              disabled={!formObject.isValid}
              type="submit"
              label={string_table.MFA_FLOW_BUTTON}
              stretch={true}
              classNames={['mt-4']}
            />
          </Form>
        </div>

        <>
          {currentFactor.type === 'SMS' && (
            <>
              <div className={'mt-4 resend-code-container'}>
                <Text text={string_table.MFA_FLOW_RESEND_CODE_TEXT} as={'span'} />{' '}
                <Button onClick={sendCodeClicked} label={string_table.MFA_FLOW_RESEND_CODE_LINK} variant={'tertiary'} />
              </div>
              <Loader classNames={['mt-4']} loaderState={loader.loaderState} />
            </>
          )}
        </>

        <div className={'accordion'}>
          <Accordion classNames={['mt-4 accordion']} title={string_table.HOME_2FA_HAVINGTROUBLE}>
            {verifiedFactors.length > 0 && (
              <div className="mb-5">
                <label className="lsux-form-field-container__label">{string_table.HOME_2FA_ALTERNATE}</label>

                <Select options={otherFactors} value={currentFactor.id} onValueChange={onSelectChange} />
              </div>
            )}

            <div className="mb-5">
              <label className="lsux-form-field-container__label">{string_table.HOME_2FA_STILLHAVINGTROUBLE}</label>

              <Text textSize="small">{string_table.HOME_2FA_PHONE.replace('%phone%', pplsi.phoneNumber)}</Text>
            </div>
          </Accordion>
        </div>
      </div>
    </>
  );
};
