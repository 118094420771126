import React, { FC, useState } from 'react';
import { GridRow, useLoader, Loader } from '@legalshield/adonis-ux-framework';

import EntitlementsService from '../../../services/entitlements.service';
import { DetermineFlow } from '../determineFlow/DetermineFlow';
import { EntitlementSelectFlow } from '../entitlementSelectFlow/EntitlementSelectFlow';
import { ForgotPasswordFlow } from '../forgotPasswordFlow/ForgotPasswordFlow';
import { GtmService, LoginService, UrlService } from '../../../services';
import { LoginFlow } from '../loginFlow/LoginFlow';
import { MultiFactorFlow } from '../multiFactorFlow/MultiFactorFlow';
import { SetPasswordFlow } from '../setPasswordFlow/SetPasswordFlow';
import { SignUpFlow } from '../signUpFlow/SignUpFlow';
import { ThemisAnalytics } from '@legalshield/frontend-analytics';
import { useThemisAnalytics } from '../../../hooks/useThemisAnalytics';
import { Realm } from '@legalshield/frontend-commons/dist/sdk/identities';

import './NewFlow.css';

const themisAnalytics = new ThemisAnalytics();

export interface NewFlowProps {
  flow?: string;
}

export const NewFlow: FC<NewFlowProps> = ({ flow }) => {
  const loader = useLoader();
  const [flowDetermined, setFlowDetermined] = useState(!!flow);
  const [loginFlow, setLoginFlow] = useState(flow === 'login');
  const [forgotPasswordFlow, setForgotPasswordFlow] = useState(flow === 'forgotPassword');
  const [multiFactorFlow, setMultiFactorFlow] = useState(false);
  const [entitlementSelectFlow, setEntitlementSelectFlow] = useState(false);
  const [setPasswordFlow, setSetPasswordFlow] = useState(flow === 'setPassword');
  const [signUpFlow, setSignUpFlow] = useState(flow === 'signUp');
  const [showUserNotFoundModal, setShowUserNotFoundModal] = useState(flow === 'emailNotExistModal');
  const [username, setUsername] = useState('');
  const [staySignedIn, setStaySignedIn] = useState(false);
  const realm = UrlService.getRealm();
  const [factors, setFactors] = useState(null);
  const [entitlements, setEntitlements] = useState(null);
  const [token, setToken] = useState('');
  const [forgotPasswordEmailSent, setForgotPasswordEmailSent] = useState(false);
  const [intent, setIntent] = useState(new URLSearchParams(window.location.search).get('intent'));

  const analytics = useThemisAnalytics();

  const doDetermineFlow = async ({ username }: { username: string }) => {
    loader.Loading();

    setUsername(username);

    const identitySearchResponse = await LoginService.identitySearch(encodeURIComponent(username), realm);

    switch (identitySearchResponse.data.status) {
      case 'IS_1': {
        setLoginFlow(true);
        setShowUserNotFoundModal(false);

        break;
      }
      case 'IS_1_3': {
        setLoginFlow(true);
        setShowUserNotFoundModal(false);

        break;
      }
      case 'IS_2': {
        setSetPasswordFlow(true);
        setShowUserNotFoundModal(false);

        break;
      }
      case 'IS_3': {
        setSignUpFlow(true);
        setShowUserNotFoundModal(false);

        break;
      }
      case 'IS_4': {
        if (realm === Realm.User) {
          setShowUserNotFoundModal(true);
          analytics.triggerCustomEvent('view_modal', { title: 'User Not Found' });
        } else {
          loader.Error(string_table.DETERMINE_FLOW_NO_ACCOUNT_FOUND);

          return;
        }

        break;
      }
    }

    setFlowDetermined(true);

    loader.Blank();
  };

  const doLoginFlow = async ({
    username,
    password,
    persist,
  }: {
    username: string;
    password: string;
    persist: boolean;
  }) => {
    loader.Loading();

    setStaySignedIn(persist);

    // @ts-ignore
    if (intent === 'mobile' && window.ReactNativeWebView) {
      // @ts-ignore
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: 'login_start',
          username: username,
          password: password,
          stay_signed_in: persist,
        })
      );
    }

    const loginResponse = await LoginService.loginUser({ username, password, persist, realm });

    if (loginResponse.status >= 200 && loginResponse.status < 300) {
      if (loginResponse.data.multifactorType) {
        setFactors(loginResponse.data.factors);
        setToken(loginResponse.data.accessToken);
        setLoginFlow(false);
        setMultiFactorFlow(true);
      } else if (UrlService.isLsApi()) {
        const response: { data: any; status: any } = await EntitlementsService.getEntitlements();

        let entitlements = response.data;

        entitlements = entitlements.filter((entitlement: any) => entitlement.status === 'ACTIVE');

        if (UrlService.getProductFamily() === 'Associate') {
          entitlements = entitlements.filter((entitlement: any) => entitlement.product.productFamily === 'Associate');
        }

        if (entitlements.length > 1) {
          setEntitlements(entitlements);

          setLoginFlow(false);
          setEntitlementSelectFlow(true);
        } else {
          await doEntitlementSelectFlow({
            friendlyId: entitlements[0]?.friendlyId,
            accountType: entitlements[0]?.product.productFamily,
          });
        }
      } else {
        GtmService.recordLoginGtmEvent();

        if (intent === 'mobile') {
          // @ts-ignore
          window.ReactNativeWebView.postMessage(
            JSON.stringify({
              type: 'login_complete',
              access_token: loginResponse.data.accessToken,
            })
          );
        } else {
          doNavigateToAppFromPathParam();
        }
      }

      loader.Blank();
    } else if (loginResponse.status === 401) {
      loader.Error(string_table.HOME_INVALID_CREDENTIALS);
    } else {
      loader.Error(string_table.HOME_SOMETHING_WRONG);
    }
  };

  const doForgotPasswordFlow = async ({ email }: { email: string }) => {
    loader.Loading();

    try {
      const sendPasswordResetEmailResponse = await LoginService.sendResetPasswordEmail({
        email: email,
        realm,
      });

      if (sendPasswordResetEmailResponse.status >= 200 && sendPasswordResetEmailResponse.status < 300) {
        loader.Blank();

        setForgotPasswordEmailSent(true);

        themisAnalytics.triggerEvent('forgot-pw-success');
      }
    } catch {
      loader.Error(string_table.APP_FAILURE);

      themisAnalytics.triggerEvent('forgot-pw-failure');
    }
  };

  const doEntitlementSelectFlow = async ({ friendlyId, accountType }: { friendlyId: string; accountType: string }) => {
    GtmService.recordLoginGtmEvent();

    window.location.href = UrlService.getLoggedInRedirect({
      appOverride: null,
      friendlyId,
      accountType,
    });
  };

  const doSetPasswordFlow = async ({ email, useLoader }: { email: string; useLoader: boolean }) => {
    if (useLoader) loader.Loading();

    try {
      const sendVerifyEmailResponse = await LoginService.sendVerifyIdentityEmail({
        email: email,
        realm: realm,
      });

      if (sendVerifyEmailResponse.status >= 200 && sendVerifyEmailResponse.status < 300) {
        if (useLoader) loader.Blank();

        themisAnalytics.triggerEvent('verify-identity-sent');

        return;
      }
    } catch {}

    loader.Error(string_table.APP_FAILURE);

    themisAnalytics.triggerEvent('verify-identity-failure');
  };

  const doSignUpFlow = async ({ email, password, persist }: { email: string; password: string; persist: boolean }) => {
    loader.Loading();

    if (intent === 'mobile') {
      // @ts-ignore
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: 'login_start',
          username: username,
          password: password,
          stay_signed_in: persist,
        })
      );
    }

    try {
      const signUpResponse = await LoginService.signUpUser({
        email: email,
        password: password,
        persist: persist,
        realm: realm,
      });
      if (signUpResponse.status >= 200 && signUpResponse.status < 300) {
        // trigger recommended GA4 SignUp event
        GtmService.recordSignUpGtmEvent();

        if (intent === 'mobile') {
          // @ts-ignore
          window.ReactNativeWebView.postMessage(
            JSON.stringify({
              type: 'login_complete',
              access_token: signUpResponse.data.accessToken,
            })
          );
        } else {
          doNavigateToAppFromPathParam();
        }

        loader.Blank();

        return;
      } else if (signUpResponse.status === 418) {
        loader.Error(string_table.SIGN_UP_FLOW_ACCOUNT_EXISTS);

        return;
      }
    } catch {}

    loader.Error(string_table.SIGNUP_GENERIC_ERROR);
  };

  const doNavigateTo = async (appOverride: string, pathOverride: string | null = null) => {
    window.location.href = UrlService.getLoggedInRedirect({ appOverride, pathOverride });
  };

  const doNavigateToAppFromPathParam = async () => {
    window.location.href = UrlService.getLoggedInRedirect({});
  };

  const doBackToDetermineFlow = async (username: string | null = null) => {
    if (username) {
      setUsername(username);
    }

    loader.Blank();

    setLoginFlow(false);
    setMultiFactorFlow(false);
    setFlowDetermined(false);
    setForgotPasswordFlow(false);
    setSignUpFlow(false);
    setSetPasswordFlow(false);
    setShowUserNotFoundModal(false);
  };

  const doMultiFactorFlow = async ({ code, factorId }: { code: string; factorId: string }) => {
    loader.Loading();

    const verifyFactorResponse = await LoginService.factorUser({
      accessToken: token,
      factorId: factorId,
      code: code,
      persist: staySignedIn,
    });

    if (verifyFactorResponse.status >= 200 && verifyFactorResponse.status < 300) {
      GtmService.recordLoginGtmEvent();

      if (UrlService.isLsApi()) {
        const response: { data: any; status: any } = await EntitlementsService.getEntitlements();

        let entitlements = response.data;

        entitlements = entitlements.filter((entitlement: any) => entitlement.status === 'ACTIVE');

        if (UrlService.getProductFamily() === 'Associate') {
          entitlements = entitlements.filter((entitlement: any) => entitlement.product.productFamily === 'Associate');
        }

        if (entitlements.length > 1) {
          setEntitlements(entitlements);

          setMultiFactorFlow(false);
          setEntitlementSelectFlow(true);
        } else {
          doEntitlementSelectFlow({
            friendlyId: entitlements[0]?.friendlyId,
            accountType: entitlements[0]?.product.productFamily,
          });
        }
      } else {
        if (intent === 'mobile') {
          // @ts-ignore
          window.ReactNativeWebView.postMessage(
            JSON.stringify({
              type: 'login_complete',
              access_token: verifyFactorResponse.data.accessToken,
            })
          );
        } else {
          doNavigateToAppFromPathParam();
        }
      }

      loader.Blank();
    } else if (verifyFactorResponse.status === 401) {
      loader.Error(string_table.HOME_INVALID_CODE);
    } else {
      loader.Error(string_table.HOME_SOMETHING_WRONG);
    }
  };

  const doNavigateToSignUp = (e: any) => {
    e.preventDefault(); // Critical for React Native WebView. Prevents link from taking control over the function nav.

    loader.Blank();

    setShowUserNotFoundModal(false);
    setFlowDetermined(true);
    setSignUpFlow(true);
    setFlowDetermined(true);
    setLoginFlow(false);
  };

  return (
    <>
      <GridRow variant="pillar">
        <div className={'container'}>
          <Loader loaderState={loader.loaderState} />

          {!flowDetermined ? (
            <DetermineFlow
              onContinue={doDetermineFlow}
              onBack={doBackToDetermineFlow}
              username={username}
              showUserNotFoundAlert={showUserNotFoundModal}
            />
          ) : (
            <></>
          )}
          {flowDetermined && showUserNotFoundModal ? (
            <DetermineFlow
              onContinue={doDetermineFlow}
              onBack={doBackToDetermineFlow}
              username={username}
              showUserNotFoundAlert={showUserNotFoundModal}
              continueToCreateAccount={doNavigateToSignUp}
            />
          ) : (
            <></>
          )}
          {flowDetermined && loginFlow ? (
            <LoginFlow onContinue={doLoginFlow} onBack={doBackToDetermineFlow} username={username} />
          ) : (
            <></>
          )}
          {flowDetermined && forgotPasswordFlow ? (
            <ForgotPasswordFlow
              onContinue={doForgotPasswordFlow}
              onBack={doBackToDetermineFlow}
              email={username}
              forgotPasswordEmailSent={forgotPasswordEmailSent}
            />
          ) : (
            <></>
          )}
          {flowDetermined && multiFactorFlow ? (
            <MultiFactorFlow
              onContinue={doMultiFactorFlow}
              onBack={doBackToDetermineFlow}
              factors={factors}
              accessToken={token}
            />
          ) : (
            <></>
          )}
          {flowDetermined && signUpFlow ? (
            <SignUpFlow onContinue={doSignUpFlow} onBack={doBackToDetermineFlow} email={username} />
          ) : (
            <></>
          )}
          {flowDetermined && setPasswordFlow ? (
            <SetPasswordFlow onContinue={doSetPasswordFlow} email={username} />
          ) : (
            <></>
          )}
          {flowDetermined && entitlementSelectFlow ? (
            <EntitlementSelectFlow onContinue={doEntitlementSelectFlow} entitlements={entitlements} />
          ) : (
            <></>
          )}
        </div>
      </GridRow>
    </>
  );
};
