/**
 * @description CompleteYourAccount component
 * - routes: /complete-your-account/update
 * - overall functionality: user can complete their account
 * - page specific functionality: component that allows the user to complete their account by creating a password
 * - components: PasswordForm
 */

import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { Loader, useLoader, useForm, Button, Heading, Form, GridRow, GridCol } from '@legalshield/adonis-ux-framework';
import { LoginService, UrlService } from '../../../services';
import { useDocTitle } from '../../../hooks';
import { PasswordForm } from '../../passwordForm/PasswordForm';

export const CompleteYourAccount = () => {
  // Set the document title
  useDocTitle(string_table.COMPLETE_ACCOUNT_TITLE);

  // Get the navigation and location hooks
  const navigate = useNavigate();
  const location = useLocation();

  // Initialize the loader
  const loader = useLoader();

  // Get the realm from the URL
  const realm = UrlService.getRealm();

  // Set up form state and form object using the PasswordForm fields
  const [formValues, formObject] = useForm(PasswordForm.passwordFields, null);

  // Extract the password value from the form state
  const { password } = formValues;

  // Handle form submission
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    // Show the loader
    loader.Loading();

    // Send the password reset request to the server
    LoginService.sendPasswordReset({ password: password.value, realm }).then(
      () => {
        // Navigate to the home page after successful password reset
        navigate('/');

        // Set the location state to indicate a password reset
        location.state = 'reset';
        return;
      },
      () => {
        // Show an error message in the loader if the password reset fails
        loader.Error(string_table.VERIFY_INVALID);
      }
    );
  };

  return (
    <>
      <GridRow variant="pillar">
        <GridCol>
          {/* Show the loader */}
          <Loader loaderState={loader.loaderState} />

          {/* Display the page heading */}
          <Heading as="T20" text={string_table.COMPLETE_ACCOUNT_TITLE} />

          {/* Display a message to create a new password */}
          <div className="form-field-container mt-5">{string_table.VERIFY_CREATE_NEW}</div>

          {/* Render the password form */}
          <Form onSubmit={handleSubmit}>
            {formObject.renderForm()}

            {/* Submit button */}
            <Button
              label={string_table.VERIFY_BUTTON}
              stretch
              type="submit"
              disabled={!formObject.isValid}
              classNames={['mt-5']}
            />
          </Form>
        </GridCol>
      </GridRow>
    </>
  );
};
