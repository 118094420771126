/**
 * @description Old PasswordForm component
 * - routes: /reset-password/update, /complete-your-account/update, /sign-up
 * - overall functionality: user can see the password strength
 * - page specific logic: component that contains functionality for password field features
 * - list of pages, flows that lead here: none
 * - components used: PasswordHelp
 */
import React, { ReactElement } from 'react';
import { PasswordHelp } from '../passwordHelp/PasswordHelp';
import { IFields } from '@legalshield/adonis-ux-framework';

// Function to validate the password strength
const ValidatePassword = (value: string) => {
  return {
    caseValid: /[a-z]/.test(value) && /[A-Z]/.test(value), // Check if password contains both lowercase and uppercase letters
    lengthValid: value.length >= 8, // Check if password length is at least 8 characters
    numberValid: /\d+/g.test(value), // Check if password contains at least one number
    specialValid: /[~`!@#$%^&*()_+\-=\[\]{};':'\\|,.<>\/?]/g.test(value), // Check if password contains at least one special character
  };
};

// Function to validate the password on input change
const onPasswordValidate = (value: string) => {
  const p = ValidatePassword(value);

  return p.lengthValid && p.caseValid && p.specialValid && p.numberValid; // Return true if all password strength criteria are met
};

// Function to render the password help component
const onPasswordRender = (inputValues: Record<string, IFields>): ReactElement => {
  const p = ValidatePassword(inputValues['password'].value);
  return (
    <PasswordHelp
      lengthValid={p.lengthValid}
      caseValid={p.caseValid}
      numberValid={p.numberValid}
      specialValid={p.specialValid}
    />
  );
};

// Define the passwordFields object with password field configuration
const passwordFields: Record<string, IFields> = {
  password: {
    type: 'password',
    label: string_table.PWFORM_PASSWORD,
    value: '',
    errorMessage: string_table.PWFORM_ERROR_STRENGTH,
    required: true,
    onValidate: onPasswordValidate,
    onPostRender: onPasswordRender,
  },
};

export const PasswordForm = {
  passwordFields,
};
