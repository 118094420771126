import React from 'react';

import { Button, Container, Heading, Image, Text } from '@legalshield/adonis-ux-framework';
import { useNavigate } from 'react-router';

// @ts-ignore
import somethingWentWrong from './images/SomethingWentWrong.png';

export const PageNotFound = () => {
  const navigate = useNavigate();

  const signIn = () => {
    navigate('/');
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Container flexbox flexDirection="column" alignItems="center" justifyContent="center">
      <div className="content mt-8">
        <Heading as="T28" text={string_table.PAGE_NOT_FOUND_TITLE} />
        <div className={'mt-5'}>
          <Text as="p" text={string_table.PAGE_NOT_FOUND_SUB_TEXT} />
        </div>

        <div className={'mt-5'}>
          <Image alt="404 Page" src={somethingWentWrong} />
        </div>

        <div className={'mt-4'}>
          <Button
            label={string_table.SIGNUP_EMAIL_HASPASSWORD_SIGNIN}
            stretch={true}
            variant={'primary'}
            onClick={signIn}
          />
        </div>
        <div className={'mt-4'}>
          <Button
            label={string_table.GO_BACK}
            stretch={true}
            variant={'tertiary'}
            onClick={goBack}
          />
        </div>
      </div>
    </Container>
  );
};
