/**
 * @description Old Login page
 * - routes: /login
 * - components: PasswordFactor, PossessionFactor, SupportCard
 * - overall functionality: login user with username and password fields.
 * - list of pages, flows that lead here: login, forgot-email-username,
 * - page specific logic: login user with username and password fields.
 * - differences between old and new: new login page contains only username field and feature to retrieve username
 */

// Importing necessary libraries, components and hooks
import {
  Button,
  Container,
  GridCol,
  GridRow,
  Heading,
  Loader,
  Modal,
  Text,
  useLoader,
} from '@legalshield/adonis-ux-framework';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { GTM_SIGN_IN } from '../../../config/constants';
import { useDocTitle, useCheckoutStep } from '../../../hooks';
import { CustomTitleService, LoginService, UrlService, GtmService } from '../../../services';
import { PasswordFactor } from './passwordFactor/PasswordFactor';
import { PossessionFactor } from './possessionFactor/PossessionFactor';
import SupportCard from '../supportCard/SupportCard';

import './Login.css';

export const Login: React.FC = () => {
  useDocTitle(string_table.HOME_TITLE);
  useCheckoutStep(GTM_SIGN_IN);

  const title = CustomTitleService.getTitleByApp('HOME');

  const location = useLocation();

  const loader = useLoader();

  const [factors, setFactors] = useState(undefined);
  const [token, setToken] = useState('');
  const [persist, setPersist] = useState(false);
  const [exposedModalData, setExposedModalData] = useState(null);

  useEffect(() => {
    if (location.state === 'reset') {
      loader.Success(string_table.HOME_SUCCESS_PASSWORD_RESET);
    } else {
      loader.Blank();
    }
  }, [location]);

  useEffect(() => {
    window.monetateQ = window.monetateQ || [];
    window.monetateQ.push(['setPageType', 'SignIn']);
    window.monetateQ.push(['trackData']);
  }, []);

  const cancelPossession = () => {
    setFactors(undefined);
  };

  const verifyPossession = async (id: string, code: string) => {
    loader.Loading();
    const results = await LoginService.factorUser({
      accessToken: token,
      factorId: id,
      code: code,
      persist: !!persist,
    });
    if (results.status >= 200 && results.status < 300) {
      GtmService.recordLoginGtmEvent();
      window.location.href = UrlService.getLoggedInRedirect({});
      return;
    } else if (results.status === 401) {
      loader.Error(string_table.HOME_INVALID_CODE);
      return;
    }

    loader.Error(string_table.HOME_SOMETHING_WRONG);
  };

  const realm = UrlService.getRealm();
  const app = UrlService.getApp();

  // Handles the password credential; input is the data from the password request
  const handlePassword = (data: any) => {
    if (data.multifactorType) {
      setFactors(data.factors);
      setToken(data.accessToken);
    } else {
      GtmService.recordLoginGtmEvent();
      window.location.href = UrlService.getLoggedInRedirect({});
      return;
    }
  };

  const closeModal = () => {
    handlePassword(exposedModalData);
    setExposedModalData(null);
  };

  const loginUser = async ({
    username,
    password,
    persist,
  }: {
    username: string;
    password: string;
    persist: boolean;
  }) => {
    loader.Loading();

    setPersist(persist);

    try {
      // Attempt to log in the user with the provided username and password
      const results = await LoginService.loginUser({ username, password, persist, realm });
      // If the login was successful (HTTP status 200-299)
      if (results.status >= 200 && results.status < 300) {
        // If the user's data has been exposed
        if (results.data.hasBeenExposed) {
          // Show a modal with information about the data exposure
          setExposedModalData(results.data);
        } else {
          // Otherwise, handle the successful password login
          handlePassword(results.data);
        }
        loader.Blank();
        return;
      }
      // If the login was unsuccessful due to invalid credentials (HTTP status 401)
      else if (results.status === 401) {
        loader.Error(string_table.HOME_INVALID_CREDENTIALS);
        return;
      }
    } catch {
      loader.Error(string_table.HOME_SOMETHING_WRONG);
      return;
    }
  };

  return (
    <>
      {/* If the user's data has been exposed, show a modal with information about the data exposure */}
      {exposedModalData && (
        <Modal position="top" closeFunction={closeModal}>
          <Modal.Title>{string_table.HOME_EXPOSED}</Modal.Title>
          <Container classNames={['pt-5']}>
            <Text>{string_table.HOME_EXPOSED_TEXT}</Text>
          </Container>
          <Modal.Actions>
            <Button label={string_table.HOME_EXPOSED_OK} onClick={closeModal} />
          </Modal.Actions>
        </Modal>
      )}
      <GridRow variant="pillar">
        <GridCol>
          <Heading as="T28" text={title} />
          <Loader loaderState={loader.loaderState} />
          {factors === undefined ? <PasswordFactor onLogin={loginUser} /> : <></>}
          {factors !== undefined ? (
            <PossessionFactor onVerify={verifyPossession} onCancel={cancelPossession} token={token} factors={factors} />
          ) : (
            <></>
          )}
          {app && app.includes('checkout') ? <SupportCard /> : <></>}
        </GridCol>
      </GridRow>
    </>
  );
};
