const getTitleByApp = (page: string) => {
  const params = new URLSearchParams(window.location.search);
  const app = params.get('app');
  let title = '';
  switch (app) {
    case 'checkout':
    case 'finalcheckout':
    case 'checkoutv3':
      title = string_table[`${page.toUpperCase()}_TITLE_CHECKOUT`];
      break;
    case 'activate':
      title = string_table[`${page.toUpperCase()}_TITLE_ACTIVATE`];
      break;
    default:
      title = string_table[`${page.toUpperCase()}_TITLE`];
      break;
  }
  return title;
};

export default {
  getTitleByApp,
};
