/**
 * @description This component is responsible for rendering the password help.
 * - routes: none
 * - overall functionality: user can see the password strength
 * - components: PasswordHelp, IFields
 * - page specific logic: user can see the password strength
 */

import React, { ReactElement } from 'react';
import { PasswordHelp } from '../passwordHelp/PasswordHelp';
import { IFields } from '@legalshield/adonis-ux-framework';

// Function to validate the password strength
const validatePassword = (value: string) => {
  return {
    caseValid: /[a-z]/.test(value) && /[A-Z]/.test(value), // Check if the password contains both lowercase and uppercase letters
    lengthValid: value.length >= 8, // Check if the password length is at least 8 characters
    numberValid: /\d+/g.test(value), // Check if the password contains at least one number
    specialValid: /[~`!@#$%^&*()_+\-=\[\]{};':'\\|,.<>\/?]/g.test(value), // Check if the password contains at least one special character
  };
};

// Function to validate the password on input change
const onPasswordValidate = (value: string) => {
  const passwordValidation = validatePassword(value);

  // Return true if all password validation checks pass
  return (
    passwordValidation.lengthValid &&
    passwordValidation.caseValid &&
    passwordValidation.specialValid &&
    passwordValidation.numberValid
  );
};

// Function to render the password help component
const onPasswordRender = (inputValues: Record<string, IFields>): ReactElement => {
  const passwordValidation = validatePassword(inputValues['password'].value);

  // Render the PasswordHelp component with the password validation results
  return (
    <PasswordHelp
      lengthValid={passwordValidation.lengthValid}
      caseValid={passwordValidation.caseValid}
      numberValid={passwordValidation.numberValid}
      specialValid={passwordValidation.specialValid}
    />
  );
};

// Define the passwordFields object with the password input field configuration
const passwordFields: Record<string, IFields> = {
  password: {
    autoComplete: 'new-password',
    errorMessage: string_table.PWFORM_ERROR_STRENGTH,
    label: string_table.PWFORM_PASSWORD,
    onPostRender: onPasswordRender,
    onValidate: onPasswordValidate,
    placeHolder: string_table.PWFORM_PASSWORD,
    required: true,
    type: 'password',
    value: '',
  },
};

// Export the NewPasswordInput component with the passwordFields object
export const NewPasswordInput = {
  passwordFields,
};
