/**
 * @description Forgot Password Flow page
 * - routes: new-forgot-password
 * - overall functionality: send reset password email to user based on email address and url
 * - list of pages, flows that lead here: login, home, LoginFlow
 * - page specific logic: page directs to the next page based on website and email address
 */

import React, { FC } from 'react';
import { Button, Form, Heading, useForm, Text, Image } from '@legalshield/adonis-ux-framework';

import { UrlService } from '../../../services';

// @ts-ignore
import emailSentImageLegalShield from './images/legalshield/email.svg';
// @ts-ignore
import emailSentImageIDShield from './images/idshield/email.svg';

import './ForgotPasswordFlow.css';

export interface ForgotPasswordFlowProps {
  onContinue({ email }: { email: string }): void;
  onBack(username: string | null): void;
  email: string | null;
  forgotPasswordEmailSent: boolean;
}

export const ForgotPasswordFlow: FC<ForgotPasswordFlowProps> = ({
  onContinue,
  onBack,
  email,
  forgotPasswordEmailSent,
}) => {
  const [formValues, formObject] = useForm(
    {
      email: {
        autoComplete: 'username',
        errorMessage: string_table.FORGOT_PASSWORD_FLOW_EMAIL_ADDRESS_REQUIRED, // Error message to display if the email input field is empty
        label: string_table.FORGOT_PASSWORD_FLOW_EMAIL_ADDRESS, // Label for the email input field
        placeHolder: string_table.FORGOT_PASSWORD_FLOW_EMAIL_ADDRESS, // Placeholder text for the email input field
        required: true, // Email input field is required
        type: 'email',
        value: email || new URLSearchParams(window.location.search).get('email') || '',
      },
    },
    null
  );
  const isIDShield = UrlService.isIDShield();

  const forgotPasswordFlow = (e: React.FormEvent) => {
    e.preventDefault();

    onContinue({ email: formValues.email.value }); // Call the onContinue function with the entered email value
  };

  const backArrowClicked = () => {
    onBack(new URLSearchParams(window.location.search).get('email')); // Call the onBack function with the email value from the URL
  };

  return (
    <>
      {!forgotPasswordEmailSent && (
        <>
          <div className={'mt-4'}>
            <Button
              iconColor="N700"
              iconLeft="arrow_left_md"
              variant="tertiary"
              onClick={backArrowClicked}
              iconSize={'medium'}
            />
          </div>

          <div className={'p-4'}>
            <div className={'mt-4'}>
              {/** Display the title of the forgot password flow */}
              <Heading as="T28" text={string_table.FORGOT_PASSWORD_FLOW_TITLE} />
            </div>

            <div className={'mt-6'}>
              {/* Display the subtext for the forgot password flow */}
              <Text text={string_table.FORGOT_PASSWORD_FLOW_SUB_TEXT} />
            </div>

            <div className={'mt-8'}>
              <Form onSubmit={forgotPasswordFlow} noValidate>
                {/* Render the form with the email input field */}
                {formObject.renderForm()}
                <Button
                  disabled={!formObject.isValid} // Disable the button if the form is not valid
                  type="submit"
                  label={string_table.FORGOT_PASSWORD_FLOW_BUTTON} // Label for the submit button
                  stretch={true}
                  classNames={['mt-4']}
                />
              </Form>
            </div>

            <hr className={'mt-8'} />

            <div className={'mt-8'}>
              {/* Display the "Forgot your email?" heading */}
              <Heading as="T28" text={string_table.FORGOT_PASSWORD_FLOW_FORGOT_EMAIL} />
            </div>
            <div className={'mt-6'}>
              {/* Display the subtext for forgot email */}
              <Text text={string_table.FORGOT_PASSWORD_FLOW_FORGOT_EMAIL_SUB_TEXT} />
            </div>

            <div className={'mt-6'}>
              {/* Display the "Please contact us" text and the contact number */}
              <Text text={string_table.FORGOT_PASSWORD_FLOW_PLEASE_CONTACT_US} />
              <Text text={'(800) 654-7757'} />
            </div>

            <div className={'mt-4'}>
              {/* Display the "For associates" text and the contact number */}
              <Text text={string_table.FORGOT_PASSWORD_FLOW_FOR_ASSOCIATES} />
              <Text text={'(580) 436-7424'} />
            </div>
          </div>
        </>
      )}
      {forgotPasswordEmailSent && (
        <>
          <div className={'p-4'}>
            <div className={'mt-4'}>
              {/* Display the "Email Sent" heading */}
              <Heading as="T28" text={string_table.FORGOT_PASSWORD_FLOW_EMAIL_SENT} />
            </div>

            <div className={'mt-5'}>
              <Text
                text={string_table.FORGOT_PASSWORD_FLOW_EMAIL_SENT_SUB_TEXT} // Display the subtext for email sent
                textSize={'large'}
              />
            </div>

            <div className={'mt-12 email-sent-container'}>
              {/* Display the email sent image */}
              <Image src={isIDShield ? emailSentImageIDShield : emailSentImageLegalShield} />
            </div>

            <Button
              label={string_table.FORGOT_PASSWORD_FLOW_EMAIL_SENT_BUTTON} // Label for the email sent button
              variant="tertiary"
              stretch={true}
              classNames={['mt-12']}
              onClick={forgotPasswordFlow} // Call the forgotPasswordFlow function on button click
            />
            <Button
              label={string_table.HOME_SIGN_IN}
              variant="primary"
              stretch={true}
              classNames={'mt-4'}
              onClick={backArrowClicked}
            />
          </div>
        </>
      )}
    </>
  );
};
