import Cookies from 'js-cookie';

import { SDKDirect } from '@legalshield/frontend-commons';
import UrlService from '../services/url.service';

const getEntitlements = async (): Promise<{ data: any; status: number }> => {
  const url = `https://entitlements.api.${UrlService.getDomain()}/v1/identities/${getInfoUserSub()}/entitlements`;

  return await SDKDirect.getJson(url);
};

const getInfoUserSub = (): string => {
  const jwtBody: any = JSON.parse(Cookies.get('info_user')!);

  return jwtBody['sub'] as string;
};

export default {
  getEntitlements,
};
