/**
 * @description New PasswordHelp component
 * - routes: none
 * - overall functionality: user can see the password strength used in NewPasswordInput
 * - page specific logic: user can see the password strength
 * - list of pages, flows that lead here: NewPasswordInput
 */

import React from 'react';
import { Icon, Text, Popover } from '@legalshield/adonis-ux-framework';
import './PasswordHelp.css';

export interface PasswordHelpProps {
  caseValid: boolean;
  numberValid: boolean;
  lengthValid: boolean;
  specialValid: boolean;
}

export const PasswordHelp: React.FC<PasswordHelpProps> = ({
  caseValid,
  numberValid,
  lengthValid,
  specialValid,
}: PasswordHelpProps) => {
  return (
    <ul className={'pl-0'}>
      {/* Display an icon indicating whether the password has at least eight characters */}
      <li className="mb-3">
        <Icon
          color={lengthValid ? 'G200' : 'R200'}
          name={lengthValid ? 'alert_check' : 'action_close'}
          size="small"
          classNames={['mr-3']}
        />
        {string_table.PWHELP_EIGHT_CHARACTERS} {/* Display a text must be eight characters */}
      </li>
      {/* Display an icon indicating whether the password has both uppercase and lowercase letters */}
      <li className="mb-3">
        <Icon
          color={caseValid ? 'G200' : 'R200'}
          name={caseValid ? 'alert_check' : 'action_close'}
          size="small"
          classNames={['mr-3']}
        />
        {string_table.PWHELP_UPPER_AND_LOWER} {/* Display a text add lowercase uppercase */}
      </li>
      {/* Display an icon indicating whether the password has at least one number */}
      <li className="mb-3">
        <Icon
          color={numberValid ? 'G200' : 'R200'}
          name={numberValid ? 'alert_check' : 'action_close'}
          size="small"
          classNames={['mr-3']}
        />
        {string_table.PWHELP_ONE_NUMBER}
        {/* Display a text message indicating whether the password has at least one number */}
      </li>
      {/* Display an icon indicating whether the password has at least one special character */}
      <li className="mb-3">
        <Icon
          color={specialValid ? 'G200' : 'R200'}
          name={specialValid ? 'alert_check' : 'action_close'}
          size="small"
          classNames={['mr-3']}
        />
        {string_table.PWHELP_ONE_SPECIAL}
        {/* Display a text message indicating password has at least one special character */}
      </li>
    </ul>
  );
};
