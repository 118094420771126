// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.access-card {
  align-items: flex-start;
  background-color: var(--brand-color-prim50);
  border: 1px solid #d3d3d3;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 16px;
}

.access-card-row {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 16px;
}

.username-hint-error {
  color: var(--r200);
}

.access-card-row-image {
  max-width: unset !important;
}

.biometric-container {
  display: flex;
}

.biometric-container > a {
  margin: 1.5rem auto 0 auto;
}

.biometric-container > a > p {
  color: #8231d4 !important;
}

.biometric-container > a > div > img {
  width: 25px;
  height: 25px;
  filter: brightness(0) saturate(100%) invert(54%) sepia(13%) saturate(1324%) hue-rotate(233deg) brightness(93%)
    contrast(99%);
}
`, "",{"version":3,"sources":["webpack://./src/components/v2/determineFlow/DetermineFlow.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,2CAA2C;EAC3C,yBAAyB;EACzB,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,aAAa;EACb,SAAS;AACX;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,YAAY;EACZ;iBACe;AACjB","sourcesContent":[".access-card {\n  align-items: flex-start;\n  background-color: var(--brand-color-prim50);\n  border: 1px solid #d3d3d3;\n  border-radius: 15px;\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n  padding: 24px 16px;\n}\n\n.access-card-row {\n  align-items: center;\n  align-self: stretch;\n  display: flex;\n  gap: 16px;\n}\n\n.username-hint-error {\n  color: var(--r200);\n}\n\n.access-card-row-image {\n  max-width: unset !important;\n}\n\n.biometric-container {\n  display: flex;\n}\n\n.biometric-container > a {\n  margin: 1.5rem auto 0 auto;\n}\n\n.biometric-container > a > p {\n  color: #8231d4 !important;\n}\n\n.biometric-container > a > div > img {\n  width: 25px;\n  height: 25px;\n  filter: brightness(0) saturate(100%) invert(54%) sepia(13%) saturate(1324%) hue-rotate(233deg) brightness(93%)\n    contrast(99%);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
