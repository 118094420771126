import { useState, useCallback } from 'react';
//import { AlertAppearance } from '../../molecules';
import { IconNames, Severity, SpinnerSize } from '@legalshield/adonis-ux-framework';

export const useLoader = () => {
  const [loaderState, setLoaderState] = useState<{
    loading: boolean;
    callback: null | (() => void);
    modalLabel: string;
    modalTitle: string;
    message: string | React.ReactNode;
    severity: Severity;
    alertBold: boolean;
    alertIcon: IconNames;
    spinnerSize: SpinnerSize;
    spinnerBlocking: boolean;
  }>({
    loading: false,
    callback: null,
    modalLabel: '',
    modalTitle: '',
    message: '',
    severity: 'default',
    alertBold: true,
    alertIcon: 'alert_success',
    spinnerSize: 'xlarge',
    spinnerBlocking: true,
  });

  const Blank = useCallback(() => {
    setLoaderState({
      ...loaderState,
      loading: false,
      message: '',
    });
  }, []);

  const Success = useCallback((message: string, { alertBold = true } = {}) => {
    setLoaderState({
      ...loaderState,
      loading: false,
      callback: null,
      modalLabel: '',
      modalTitle: '',
      message: message,
      severity: 'success',
      alertIcon: 'alert_success',
      alertBold: alertBold,
    });
  }, []);

  const Error = useCallback((message: string, { alertBold = true } = {}) => {
    setLoaderState({
      ...loaderState,
      loading: false,
      callback: null,
      modalLabel: '',
      modalTitle: '',
      message: message,
      severity: 'error',
      alertIcon: 'alert_error',
      alertBold: alertBold,
    });
  }, []);

  const Modal = useCallback((message: string, modalTitle: string, modalLabel: string, callback: () => void) => {
    setLoaderState({
      ...loaderState,
      loading: false,
      callback: callback,
      modalLabel: modalLabel,
      modalTitle: modalTitle,
      message: message,
    });
  }, []);

  const Loading = useCallback(({ spinnerSize = 'xlarge', spinnerBlocking = true } = {}) => {
    setLoaderState({
      ...loaderState,
      message: '',
      loading: true,
      spinnerSize: spinnerSize as SpinnerSize,
      spinnerBlocking: spinnerBlocking,
    });
  }, []);

  const Alert = useCallback(
    (
      message: string | React.ReactNode,
      { severity = 'default', alertBold = false, alertIcon = 'alert_success', callback = null } = {}
    ) => {
      setLoaderState({
        ...loaderState,
        loading: false,
        modalLabel: '',
        modalTitle: '',
        message: message,
        callback: callback,
        severity: severity as Severity,
        alertBold: alertBold,
        alertIcon: alertIcon,
      });
    },
    []
  );

  return { Blank, Error, Loading, Modal, Success, Alert, loaderState };
};
