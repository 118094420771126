/**
 * @description ResetPassword component
 * - routes: /reset-password/update
 * - overall functionality: user can reset their password with a new password
 * - page specific logic: component contains a field and password validation tool for creating a new password
 * - components: PasswordForm, LoginService, UrlService
 * - list of pages, flows that lead here: reset link from email
 */

import React from 'react';
import { useNavigate, useLocation } from 'react-router';
import { Button, Loader, useLoader, Heading, Form, GridRow, GridCol, useForm } from '@legalshield/adonis-ux-framework';
import { LoginService, UrlService } from '../../../services';
import Cookies from 'js-cookie';
import { useDocTitle } from '../../../hooks';
import { PasswordForm } from '../../passwordForm/PasswordForm';

export const ResetPassword: React.FC<{}> = () => {
  // Set the document title
  useDocTitle(string_table.RESET_TITLE);

  // Get the navigation and location objects from react-router
  const navigate = useNavigate();
  const location = useLocation();

  // Set up the loader state
  const loader = useLoader();

  // Set up form state and validation
  const [formValues, formObject] = useForm(PasswordForm.passwordFields, null);
  // Destructure the password value from the formValues object
  const { password } = formValues;

  // Handle form submission
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    loader.Loading();
    const realm = UrlService.getRealm();
    try {
      // Send the password reset request to the server
      const results = await LoginService.sendPasswordReset({
        password: password.value,
        realm: realm,
      });
      if (results.status >= 200 && results.status < 300) {
        navigate(`/login${Cookies.get('origin_path') || ''}`);
        location.state = 'reset';
        return;
      }
    } catch {}

    // If an error occurred during the password reset, show an error message
    loader.Error(string_table.RESET_INVALID);
  };

  return (
    <>
      <GridRow variant="pillar">
        <GridCol>
          {/* Display the loader component */}
          <Loader loaderState={loader.loaderState} />
          {/* Display the heading with the reset title */}
          <Heading as="T20" text={string_table.RESET_TITLE} />
          {/* Display a message to create a new password */}
          <div className="form-field-container">{string_table.RESET_CREATE_NEW}</div>
          {/* Render the form and handle form submission */}
          <Form onSubmit={handleSubmit}>
            {formObject.renderForm()}
            {/* Display the reset password button */}
            <Button
              label={string_table.RESET_RESET_PASSWORD}
              stretch
              type="submit"
              disabled={!formObject.isValid}
              classNames={['mt-5']}
            />
          </Form>
        </GridCol>
      </GridRow>
    </>
  );
};
