/**
 * @description Entitlement Select Flow component
 * - routes: none
 * - components: none
 * - overall functionality: prompt user to select an entitlement
 * - page specific functionality: component that displays a form to select an entitlement
 * - list of pages: none
 */

import React from 'react';

import { Button, Form, Heading, useForm, SelectOption } from '@legalshield/adonis-ux-framework';
import './EntitlementSelectFlow.css';

interface Product {
  productFamily: string;
}

interface Entitlement {
  friendlyId: string;
  product: Product;
}

export interface EntitlementSelectFlow {
  onContinue({ friendlyId, accountType }: { friendlyId: string; accountType: string }): void;
  entitlements: Array<Entitlement> | null;
}

export const EntitlementSelectFlow: React.FC<EntitlementSelectFlow> = ({
  onContinue,
  entitlements,
}: EntitlementSelectFlow) => {
  // Create an array of SelectOption objects based on the entitlements
  const options: SelectOption[] | undefined = entitlements?.map((entitlement) => {
    let option: SelectOption = {
      label: entitlement.friendlyId,
      value: entitlement.friendlyId,
    };

    return option;
  });

  // Set up form values and form object using the useForm hook
  const [formValues, formObject] = useForm(
    {
      entitlement: {
        value: options![0].value,
        type: 'select',
        options: options,
        label: string_table.ENTITLEMENT_SELECT_FLOW_SELECT_LABEL,
        required: true,
      },
    },
    null
  );

  // Handle form submission
  const entitlementSelectFlow = (e: React.FormEvent) => {
    e.preventDefault();

    // Get the selected friendlyId and corresponding accountType
    const friendlyId: string = formValues.entitlement.value;
    const accountType: string = entitlements!.filter(
      (e: Entitlement) => e.friendlyId === friendlyId
    )[0].product.productFamily;

    // Call the onContinue function with the selected friendlyId and accountType
    onContinue({ friendlyId: friendlyId, accountType: accountType });
  };

  return (
    <>
      <div className={'p-4'}>
        <div className={'mt-6'}>
          {/* Display the title of the page */}
          <Heading as="T28" text={string_table.ENTITLEMENT_SELECT_FLOW_TITLE} />
        </div>

        {/* Render the form */}
        <Form onSubmit={entitlementSelectFlow}>
          {formObject.renderForm()}

          {/* Display the "Continue" button */}
          <Button
            label={string_table.ENTITLEMENT_SELECT_FLOW_CONTINUE}
            stretch={true}
            classNames={['mt-10']}
            type="submit"
          />
        </Form>
      </div>
    </>
  );
};
