/**
 * @description This component is responsible for rendering the login form.
 * - routes: none
 * - components: URLService, TermsAndPrivacy, ForgotPasswordPrompt
 * - overall functionality: user can enter their email and password to log in
 * - list of pages, flows that lead here: DetermineFlow
 * - page specific logic: user can log in using their email and password
 */

import React, { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button, Checkbox, Divider, Form, Heading, useForm } from '@legalshield/adonis-ux-framework';
import { Realm } from '@legalshield/frontend-commons/dist/sdk/identities';

import { ForgotPasswordPrompt } from '../forgotPasswordPrompt/ForgotPasswordPrompt';
import { TermsAndPrivacy } from '../../termsAndPrivacy/TermsAndPrivacy';
import { LoginService, UrlService } from '../../../services';

export interface LoginFlowProps {
  onContinue({ username, password, persist }: { username: string; password: string; persist: boolean }): void;
  onBack(): void;
  username: string;
}

export const LoginFlow: FC<LoginFlowProps> = ({ onContinue, onBack, username }) => {
  const realm = UrlService.getRealm();
  const [searchParams, setSearchParams] = useSearchParams();
  const forceLogout = searchParams.get('forceLogout') === 'true';

  useEffect(() => {
    if (forceLogout) {
      const logoutUser = async () => {
        // Logout user
        await LoginService.logoutUser();

        // Remove forceLogout query param from URL
        searchParams.delete('forceLogout');
        setSearchParams(searchParams, { replace: true });
      };

      logoutUser();
    }
  }, [forceLogout]);

  // If username is not provided and jwt_payload and jwt_payload.ual exist, set username to jwt_payload.ual
  if (!username && jwt_payload && jwt_payload.ual) {
    username = jwt_payload.ual;
  }

  const [staySignedIn, setStaySignedIn] = useState<boolean | null>(false);

  const [formValues, formObject] = useForm(
    {
      username: {
        autoComplete: 'username',
        errorMessage: string_table.DETERMINE_FLOW_EMAIL_ADDRESS_REQUIRED,
        label: string_table.DETERMINE_FLOW_EMAIL_ADDRESS,
        placeHolder: string_table.DETERMINE_FLOW_EMAIL_ADDRESS,
        required: true,
        type: 'email',
        value: username,
      },
      password: {
        errorMessage: string_table.LOGIN_FLOW_PASSWORD_REQUIRED,
        label: string_table.LOGIN_FLOW_PASSWORD,
        placeHolder: string_table.LOGIN_FLOW_PASSWORD,
        required: true,
        type: 'password',
        value: '',
      },
    },
    null
  );

  useEffect(() => {
    // Monetate tracking
    window.monetateQ = window.monetateQ || [];
    window.monetateQ.push(['setPageType', 'SignIn']);
    window.monetateQ.push(['trackData']);

    if (username) {
      // Focus on password input if username is provided
      // @ts-ignore
      document.querySelector('input[name="password"]')!.focus();
    }
  }, []);

  const loginFlow = (e: React.FormEvent) => {
    e.preventDefault();

    // Call onContinue function with form values
    onContinue({
      username: formValues.username.value,
      password: formValues.password.value,
      persist: !!staySignedIn,
    });
  };

  const backArrowClicked = () => {
    // Call onBack function
    onBack();
  };

  const handleOkta = () => {
    // Redirect to Okta authentication
    window.location.href = '/v1/oauth/okta' + location.search;
  };

  return (
    <>
      {/* Back button */}
      <div className={'mt-4'}>
        <Button
          iconColor="N700"
          iconLeft="arrow_left_md"
          variant="tertiary"
          onClick={backArrowClicked}
          iconSize={'medium'}
        />
      </div>

      <div className={'p-4'}>
        <div className={'mt-4'}>
          {/* Login form title */}
          <Heading as="T28" text={string_table.LOGIN_FLOW_TITLE} />
        </div>

        <div className={'mt-6'}>
          {/* Login form */}
          <Form onSubmit={loginFlow} noValidate>
            {formObject.renderForm()}

            {/* Stay signed in checkbox */}
            <Checkbox
              id="persist"
              name="persist"
              rightLabel={string_table.LOGIN_FLOW_STAY_SIGNED_IN}
              checked={!!staySignedIn}
              onCheckedChange={setStaySignedIn}
              classNames={['mt-5']}
            />

            {/* Terms and privacy */}
            <TermsAndPrivacy />

            {/* Login button */}
            <Button
              disabled={!formObject.isValid}
              type="submit"
              label={string_table.LOGIN_FLOW_BUTTON}
              stretch={true}
              classNames={['mt-4']}
            />
          </Form>
        </div>

        {/* Additional options for Internal and Partner realms */}
        {(realm === Realm.Internal || realm === Realm.Partner) && (
          <>
            <Divider label={string_table.HOME_OR} classNames={['my-5']} />

            {/* Okta button */}
            <Button label={string_table.HOME_OKTA} stretch={true} classNames={['mt-5']} onClick={() => handleOkta()} />
          </>
        )}

        {/* Forgot password prompt */}
        <ForgotPasswordPrompt username={username} />
      </div>
    </>
  );
};
