/**
 * @description New SignUpFlow component
 * - routes /new-sign-up
 * - overall functionality: user can sign up for an account
 * - page specific functionality: component that begins the sign up process
 * - components: RegEx, NewPasswordInput, useCheckoutStep, useDocTitle, GTM_SIGN_UP, UrlService
 */

import React, { useEffect, useState } from 'react';

import { Button, Form, Heading, useForm, Link, Text, Image, Checkbox } from '@legalshield/adonis-ux-framework';
import { RegEx } from '@legalshield/frontend-commons';
import { NewPasswordInput } from '../../../components/newPasswordInput/NewPasswordInput';
import { useCheckoutStep, useDocTitle } from '../../../hooks';
import { GTM_SIGN_UP } from '../../../config/constants';
import { UrlService } from '../../../services';

import './SignUpFlow.css';

export interface SignUpFlowProps {
  onContinue({ email, password, persist }: { email: string; password: string; persist: boolean }): void;
  onBack(username: string | null): void;
  email: string;
}

export const SignUpFlow: React.FC<SignUpFlowProps> = ({ onContinue, onBack, email }: SignUpFlowProps) => {
  // Set document title
  useDocTitle(string_table.SIGNUP_DOC_TITLE);

  // Track checkout step for analytics
  useCheckoutStep(GTM_SIGN_UP);

  // Check if the user is signing up for IDShield
  const isIDShield = UrlService.isIDShield();

  // State to track "Stay Signed In" checkbox value
  const [staySignedIn, setStaySignedIn] = useState<boolean | null>(false);

  // Form values and form object for email and password fields
  const [formValues, formObject] = useForm(
    {
      email: {
        autoComplete: 'email',
        errorMessage: string_table.ERROR_EMAIL_PATTERN,
        label: string_table.DETERMINE_FLOW_EMAIL_ONLY,
        placeHolder: string_table.DETERMINE_FLOW_EMAIL_ONLY,
        regEx: RegEx.emailRegex,
        required: true,
        type: 'email',
        value: email,
      },
      ...NewPasswordInput.passwordFields,
    },
    null
  );

  // Focus on password field when sign up is toggled and email is present
  useEffect(() => {
    if (email) {
      // @ts-ignore
      document.querySelector('input[name="password"]')!.focus();
    }
  });

  // Handle sign up form submission
  const signUpFlow = (e: React.FormEvent) => {
    e.preventDefault();

    onContinue({
      email: formValues.email.value,
      password: formValues.password.value,
      persist: !!staySignedIn,
    });
  };

  // Handle back arrow click
  const backArrowClicked = () => {
    onBack(email);
  };

  return (
    <>
      <div className={'mt-4'}>
        {/* Back arrow button */}
        <Button
          iconColor="N700"
          iconLeft="arrow_left_md"
          variant="tertiary"
          onClick={backArrowClicked}
          iconSize={'medium'}
        />
      </div>

      <div className={'p-4'}>
        <div className={'mt-4'}>
          {/* Sign up flow title */}
          <Heading as="T28" text={string_table.SIGN_UP_FLOW_TITLE} />
        </div>

        <div className={'mt-6'}>
          <Form onSubmit={signUpFlow} noValidate>
            {/* Render form fields */}
            {formObject.renderForm()}

            {/* "Stay Signed In" checkbox */}
            <Checkbox
              id="persist"
              name="persist"
              rightLabel={string_table.LOGIN_FLOW_STAY_SIGNED_IN}
              checked={!!staySignedIn}
              onCheckedChange={setStaySignedIn}
            />

            <div className={'mt-6 p-4 terms-acceptance'}>
              {/* Terms and privacy policy links */}
              <Text text={string_table.LOGIN_FLOW_TERMS_BEGINNING} textSize={'small'} as={'span'} />
              <Link
                textSize={'small'}
                href={'https://www.pplsi.com/terms-service/'}
                text={string_table.LOGIN_FLOW_TERMS}
                textWeight={'semibold'}
                target={'_blank'}
              />
              <Text text={string_table.LOGIN_FLOW_TERMS_AND} textSize={'small'} as={'span'} />
              <Link
                textSize={'small'}
                href={'https://www.pplsi.com/privacy-policy/'}
                text={string_table.LOGIN_FLOW_PRIVACY}
                textWeight={'semibold'}
                target={'_blank'}
              />
            </div>

            {/* Submit button */}
            <Button
              disabled={!formObject.isValid}
              type="submit"
              label={string_table.LOGIN_FLOW_BUTTON}
              stretch={true}
              classNames={['mt-4']}
            />
          </Form>
        </div>
      </div>
    </>
  );
};
