import React, { forwardRef, ForwardRefRenderFunction, ReactNode, Ref } from 'react';
import {
  Alert,
  Button,
  Container,
  IconNames,
  Modal,
  Severity,
  Spinner,
  SpinnerSize,
} from '@legalshield/adonis-ux-framework';

export interface LoaderProps extends Omit<React.HTMLProps<HTMLDivElement>, 'onClick' | 'css'> {
  loaderState: {
    loading: boolean;
    message: string | ReactNode | null;
    modalTitle: string | null;
    modalLabel: string | null;
    callback: (() => void) | null;
    severity: Severity;
    alertBold: boolean;
    alertIcon: IconNames;
    spinnerSize: SpinnerSize;
    spinnerBlocking: boolean;
  };

  /**
   * Additional class names
   */
  classNames?: string[];

  /**
   * The ref to the HTML DOM element.
   */
  ref?: Ref<HTMLDivElement>;
}

const LoaderComponent: ForwardRefRenderFunction<HTMLDivElement, LoaderProps> = (
  { loaderState, classNames = [] }: LoaderProps,
  ref: LoaderProps['ref']
) => {
  if (!loaderState.loading && !loaderState.message) {
    return <></>;
  }

  const alertCallback = (e: React.MouseEvent) => {
    if (loaderState.callback) {
      loaderState.callback();
    }
  };

  const renderAlertContent = () => {
    if (typeof loaderState.message === 'string') {
      return (
        <Alert
          severity={loaderState.severity}
          onClick={alertCallback}
          dismissible={loaderState.callback ? true : false}
          content={loaderState.message}
          icon={loaderState.alertIcon}
          bold={loaderState.alertBold}
        />
      );
    }
    // Handle ReactNode case by ensuring it's a valid Element
    return (
      <Alert
        severity={loaderState.severity}
        onClick={alertCallback}
        dismissible={loaderState.callback ? true : false}
        icon={loaderState.alertIcon}
        bold={loaderState.alertBold}
      >
        {React.isValidElement(loaderState.message) ? loaderState.message : String(loaderState.message)}
      </Alert>
    );
  };

  return (
    <Container classNames={[...classNames]} ref={ref}>
      <>
        {loaderState.loading && (
          <Spinner spinnerSize={loaderState.spinnerSize} blocking={loaderState.spinnerBlocking} />
        )}
      </>
      <>
        {loaderState.message &&
          (!loaderState.modalTitle || !loaderState.modalLabel || !loaderState.callback) &&
          renderAlertContent()}
      </>
      <>
        {loaderState.message && loaderState.modalTitle && loaderState.modalLabel && loaderState.callback && (
          <Modal title={loaderState.modalTitle}>
            {React.isValidElement(loaderState.message) ? loaderState.message : String(loaderState.message)}
            <Modal.Actions>
              <Button variant="primary" label={loaderState.modalLabel} onClick={loaderState.callback} />
            </Modal.Actions>
          </Modal>
        )}
      </>
    </Container>
  );
};

export const Loader = forwardRef(LoaderComponent);
