// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  max-width: 400px;
  width: 100%;
  margin: 0;
  padding: 0;
}

.lsux-spinner__center--neutral {
  border-bottom: .2rem solid var(--brand-color-prim300) !important;
  border-left: .2rem solid var(--brand-color-prim300) !important;
  border-top: .2rem solid var(--brand-color-prim300) !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/v2/newFlow/NewFlow.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,WAAW;EACX,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,gEAAgE;EAChE,8DAA8D;EAC9D,6DAA6D;AAC/D","sourcesContent":[".container {\n  max-width: 400px;\n  width: 100%;\n  margin: 0;\n  padding: 0;\n}\n\n.lsux-spinner__center--neutral {\n  border-bottom: .2rem solid var(--brand-color-prim300) !important;\n  border-left: .2rem solid var(--brand-color-prim300) !important;\n  border-top: .2rem solid var(--brand-color-prim300) !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
