/**
 * @description Old VerifyIdentity component
 * - routes: /verify-identity
 * - overall functionality: user can verify their identity
 * - page specific functionality: component that allows the user to verify their identity by entering their email address
 * - components: Regex, LoginService, UrlService
 */

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Form, GridCol, GridRow, Heading, Loader, useForm, useLoader } from '@legalshield/adonis-ux-framework';
import { RegEx } from '@legalshield/frontend-commons';
import { useDocTitle } from '../../../hooks';
import { LoginService, UrlService } from '../../../services';
import { useThemisAnalytics } from '../../../hooks/useThemisAnalytics';

export const VerifyIdentity = () => {
  // Set the document title
  useDocTitle(string_table.VERIFY_TITLE);

  // Set the initial button label state
  const [buttonLabel, setButtonLabel] = useState(string_table.COMPLETE_ACCOUNT_SENDMAIL);

  // Set up form values and form object using useForm hook
  const [formValues, formObject] = useForm(
    {
      email: {
        autoComplete: 'username',
        errorMessage: string_table.ERROR_EMAIL_PATTERN,
        label: string_table.FORGOT_PASSWORD_EMAIL_ADDRESS,
        regEx: RegEx.emailRegex,
        required: true,
        type: 'email',
        value: '',
      },
    },
    null
  );

  // Destructure email from formValues
  const { email } = formValues;

  // Set up loader using useLoader hook
  const loader = useLoader();

  // Handle form submission
  const submitForm = async (e: React.FormEvent) => {
    e.preventDefault();

    // Show loading state
    loader.Loading();

    // Get the realm from the URL
    const realm = UrlService.getRealm();

    try {
      // Send verify identity email using LoginService
      const results = await LoginService.sendVerifyIdentityEmail({
        email: email.value,
        realm: realm,
      });

      // If the request is successful, update the button label and show success message
      if (results.status >= 200 && results.status < 300) {
        setButtonLabel(string_table.COMPLETE_ACCOUNT_RESEND_EMAIL);

        loader.Success(string_table.APP_SUCCESS.replace('%phone%', pplsi.phoneNumber));

        useThemisAnalytics().triggerEvent('verify-identity-sent');
        return;
      }
    } catch {}

    // If the request fails, show error message
    loader.Error(string_table.APP_FAILURE);

    useThemisAnalytics().triggerEvent('verify-identity-failure');
  };

  return (
    <>
      <GridRow variant="pillar">
        <GridCol>
          {/* Display the heading */}
          <Heading as="T20" text={string_table.VERIFY_TITLE} />

          {/* Display the loader */}
          <Loader loaderState={loader.loaderState} />

          {/* Display the text */}
          <div className="mt-5">{string_table.COMPLETE_ACCOUNT_ENTER_TEXT}</div>

          {/* Display the form */}
          <Form onSubmit={submitForm} noValidate>
            {formObject.renderForm(undefined)}

            {/* Display the submit button */}
            <Button type="submit" disabled={!formObject.isValid} label={buttonLabel} classNames={['mt-5']} stretch />
          </Form>

          {/* Display the back link */}
          <div className="mt-5">
            <Link to={'login' + location.search} className="link">
              {string_table.COMPLETE_ACCOUNT_BACK}
            </Link>
          </div>
        </GridCol>
      </GridRow>
    </>
  );
};
