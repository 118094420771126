/**
 * @description Old Forgot Password page
 *  - overall functionality: send reset password email to user
 * - list of pages, flows that lead here: login, forgot-email-username
 * - page specific logic: page contains field to enter password in order to retrieve email to reset password
 * - differences between old and new: This page contains field and submit. New page combines both username and password retrieval
 */

import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Heading, Form, GridRow, GridCol, Loader, useLoader, useForm } from '@legalshield/adonis-ux-framework';

import { LoginService, UrlService } from '../../../services';
import { RegEx } from '@legalshield/frontend-commons';
import { useDocTitle } from '../../../hooks';
import { useThemisAnalytics } from '../../../hooks/useThemisAnalytics';

export const ForgotPassword: FC = () => {
  // Set document title
  useDocTitle(string_table.FORGOT_PASSWORD_TITLE);

  // Initialize loader
  const loader = useLoader();

  // Set initial button label state
  const [buttonLabel, setButtonLabel] = useState(string_table.FORGOT_PASSWORD_SENDMAIL);

  // Initialize form values and form object
  const [formValues, formObject] = useForm(
    {
      email: {
        autoComplete: 'username',
        type: 'email',
        value: '',
        label: string_table.FORGOT_PASSWORD_EMAIL_ADDRESS,
        errorMessage: string_table.ERROR_EMAIL_PATTERN,
        regEx: RegEx.emailRegex,
        required: true,
      },
    },
    null
  );

  // Destructure email from form values
  const { email } = formValues;

  // Get realm from URL
  const realm = UrlService.getRealm();

  // Handle form submission
  const submitForm = async (e: React.FormEvent) => {
    e.preventDefault();

    // Show loader
    loader.Loading();

    try {
      // Send reset password email
      const results = await LoginService.sendResetPasswordEmail({ email: email.value, realm });
      if (results.status >= 200 && results.status < 300) {
        // Update button label and show success message
        setButtonLabel(string_table.FORGOT_PASSWORD_RESEND_EMAIL);
        loader.Success(string_table.APP_SUCCESS.replace('%phone%', pplsi.phoneNumber));
        // Trigger analytics event for success
        useThemisAnalytics().triggerEvent('forgot-pw-success');
        return;
      }
    } catch {
      // Show error message
      loader.Error(string_table.APP_FAILURE);
      // Trigger analytics event for failure
      useThemisAnalytics().triggerEvent('forgot-pw-failure');
      return;
    }
  };

  return (
    <GridRow variant="pillar">
      <GridCol>
        {/* Render page heading */}
        <Heading as="T28" text={string_table.FORGOT_PASSWORD_TITLE} />

        {/* Render loader */}
        <Loader loaderState={loader.loaderState} />

        {/* Render text */}
        <div className="mt-5">{string_table.FORGOT_PASSWORD_ENTER_TEXT}</div>

        {/* Render form */}
        <Form onSubmit={submitForm} noValidate>
          {formObject.renderForm()}

          {/* Render submit button */}
          <Button type="submit" disabled={!formObject.isValid} label={buttonLabel} classNames={['mt-5']} stretch />
        </Form>

        {/* Render back link */}
        <div className="mt-5">
          <Link to="/login" className="link">
            {string_table.FORGOT_PASSWORD_BACK}
          </Link>
        </div>
      </GridCol>
    </GridRow>
  );
};
