// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.resend-code-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.factor-description {
  background-color: #f5f5f5;
  border-radius: 10px;
}

.accordion {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/v2/multiFactorFlow/MultiFactorFlow.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,0BAA0B;AAC5B","sourcesContent":[".resend-code-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.factor-description {\n  background-color: #f5f5f5;\n  border-radius: 10px;\n}\n\n.accordion {\n  margin-left: 0 !important;\n  margin-right: 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
