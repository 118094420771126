/**
 * @description PasswordFactor component
 * - routes: none
 * - components: UrlService, TermsAndPrivacy, ForgotPasswordPrompt
 * - overall functionality: login user with username and password fields
 * - list of pages: none
 * - page specific logic: login user with username and password fields
 */

import React, { FC, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Alert, Button, Checkbox, Divider, Form, Text, useForm } from '@legalshield/adonis-ux-framework';
import { Realm } from '@legalshield/frontend-commons/dist/sdk/identities';

import { TermsAndPrivacy } from '../../../termsAndPrivacy/TermsAndPrivacy';
import { UrlService } from '../../../../services';

export interface PasswordFactorProps {
  onLogin({ username, password, persist }: { username: string; password: string; persist: boolean }): void;
}

export const PasswordFactor: FC<PasswordFactorProps> = ({ onLogin }) => {
  const app = UrlService.getApp();
  const isCheckout = app === 'checkoutv3';
  let initialValue = '';

  // Set initial value for the username field if jwt_payload and jwt_payload.ual exist
  if (jwt_payload && jwt_payload.ual) {
    initialValue = jwt_payload.ual;
  }

  const navigate = useNavigate();
  const realm = UrlService.getRealm();

  const [staySignedIn, setStaySignedIn] = useState<boolean | null>(false);

  // Define form values and validation rules using useForm hook
  const [formValues, formObject] = useForm(
    {
      username: {
        autoComplete: 'username',
        errorMessage: string_table.HOME_EMAIL_ADDRESS_REQUIRED,
        label: string_table.HOME_EMAIL_ADDRESS,
        placeHolder: string_table.HOME_EMAIL_ADDRESS,
        required: true,
        type: 'email',
        value: initialValue,
      },
      password: {
        errorMessage: string_table.HOME_PASSWORD_REQUIRED,
        label: string_table.HOME_PASSWORD,
        required: true,
        type: 'password',
        value: '',
      },
    },
    null
  );

  const { username, password } = formValues;

  // Handle login form submission
  const handleLogin = (e: React.FormEvent) => {
    e.preventDefault();

    // Call the onLogin function passed as a prop with the entered username, password, and staySignedIn value
    onLogin({ username: username.value, password: password.value, persist: !!staySignedIn });
  };

  // Redirect to Okta login page
  const handleOkta = () => {
    window.location.href = '/v1/oauth/okta' + location.search;
  };

  return (
    <>
      {/* Display "Resign In" message if jwt_payload and jwt_payload.ual exist */}
      {jwt_payload && jwt_payload.ual && <div className="mt-3 resignin">{string_table.HOME_RESIGN_IN}</div>}

      {/* Display "Need Account?" message and link to sign up page if realm is 'User' */}
      {realm === Realm.User && (
        <div className="mt-3" style={{ display: 'flex' }}>
          <Text text={string_table.HOME_NEED_ACCOUNT} textSize="large" classNames={['mr-3']} />
          <Link to={'/sign-up' + location.search} className="link">
            {string_table.SIGNUP_SIGN_UP}
          </Link>
        </div>
      )}

      {/* Display alert message for checkout page */}
      {isCheckout ? (
        <div className={'mt-4'}>
          <Alert
            icon={'alert_information'}
            bold={false}
            content={string_table.SIGNUP_PRIMERICA_TIP}
            severity={'default'}
            type="default"
          />
        </div>
      ) : (
        <></>
      )}

      {/* Render login form */}
      <Form onSubmit={handleLogin} noValidate>
        {formObject.renderForm()}

        {/* Checkbox to stay signed in */}
        <Checkbox
          id="persist"
          name="persist"
          rightLabel={string_table.HOME_KEEP_SIGNED_IN}
          checked={!!staySignedIn}
          onCheckedChange={setStaySignedIn}
          classNames={['mt-5']}
        />

        {/* Terms and Privacy component */}
        <TermsAndPrivacy />

        {/* Submit button */}
        <Button
          disabled={!formObject.isValid}
          type="submit"
          label={string_table.HOME_SIGN_IN}
          stretch={true}
          classNames={['mt-5']}
        />
      </Form>

      {/* Display Okta button for 'Internal' or 'Partner' realm */}
      {(realm === Realm.Internal || realm === Realm.Partner) && (
        <>
          <div className={'my-5'}>
            <Divider label={string_table.HOME_OR} />
          </div>
          <Button label={string_table.HOME_OKTA} stretch={true} classNames={['mt-5']} onClick={() => handleOkta()} />
        </>
      )}
      <div className={'mt-4'}>
        <Button
          label={string_table.LOGIN_FLOW_FORGOT_PASSWORD}
          stretch={true}
          variant={'tertiary'}
          onClick={() => navigate('/forgot-password')}
        />
      </div>
    </>
  );
};
